@use "../abstracts" as *;

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 1.5rem;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.3);
  @media (min-width: $br-medium) {
    padding: 4rem;
  }
  @media print {
    position: static;
  }
  .modal-inner {
    padding: 2rem;
    background-color: white;
    max-width: 1420px;
    margin: 0 auto;
    @media (min-width: $br-medium) {
      padding: 5rem;
    }
  }
  .modal-controls {
    display: flex;
    justify-content: flex-end;
    @media print {
      display: none;
    }
    ul {
      display: flex;
      align-items: center;
      font-size: 2.6rem;
      li:not(:last-child) {
        margin-right: 2rem;
      }
    }
    .modal-close-button {
      margin-left: 5rem;
      width: 35px;
      height: 35px;
      flex-shrink: 0;
      span {
        display: block;
        width: 35px;
        height: 2px;
        background-color: $blue;
        position: relative;
        transform: rotate(45deg);
        &::after {
          content: "";
          width: 35px;
          height: 2px;
          background-color: $blue;
          position: absolute;
          top: 0;
          left: 0;
          transform: rotate(90deg);
          transform-origin: 50%;
        }
      }
      @media (min-width: $br-large) {
        width: 50px;
        height: 50px;
        span {
          width: 50px;
          height: 3px;
          &::after {
            width: 50px;
            height: 3px;
          }
        }
      }
    }
  }
  .modal-toggle {
    display: flex;
    justify-content: flex-end;
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-top: 0.6rem;
    button {
      display: flex;
      align-items: center;
    }
    i {
      margin-left: 0.6rem;
    }
  }
}

@use "../abstracts" as *;

.glossar-section {
  .filter-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    & > button {
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
    @media (min-width: $br-medium) {
      justify-content: center;
    }
  }
  .expand-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 4rem;
    margin-bottom: -3rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
    & > button:not(:last-child) {
      margin-right: 3rem;
    }
  }
}

@use "../abstracts" as *;

.skiplinks-menu {
  position: absolute;
  z-index: 10;
  .skiplinks {
    position: relative;
    z-index: 10;
    background-color: #fff;
    list-style: none;
    margin: 0;
  }
  .skiplink a {
    display: block;
    position: absolute;
    top: -600px;
    overflow: hidden;
    z-index: 10;
    background-color: #fff;
    padding: calc(1rem - 0.25vw) calc(1rem + 0.25vw);
    margin-bottom: 20px;
    line-height: inherit;
    color: #fff;
    word-break: initial;
    min-width: 210px;
    &:focus {
      top: 0px !important;
      left: 0 !important;
      height: auto !important;
      width: auto !important;
      color: $text-color;
      background-color: $blue;
      color: white;
      border-color: $text-color;
      text-align: center;
    }
  }
}

@use "../abstracts" as *;

.session-box-section {
  padding-bottom: 5rem;
  .heading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2rem;
    i {
      margin-left: 0.8rem;
      font-size: 3.2rem;
    }
    button {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      line-height: 1.8rem;
      font-weight: 700;
    }
    @media (min-width: $br-medium) {
      flex-direction: row;
      margin-bottom: 0;
      a {
        margin-left: 2rem;
      }
    }
  }
  .inner {
    @media (min-width: $br-medium) {
      display: flex;
    }
    @media print {
      page-break-inside: avoid;
    }
  }
  .image-wrapper {
    position: relative;
    @media (min-width: $br-medium) {
      width: 50%;
    }
    img {
      height: 100%;
      object-fit: cover;
    }
    .image-heading {
      position: absolute;
      top: 5rem;
      left: 5rem;
      color: white;
      @media print {
        color: $text-color;
      }
      span {
        display: inline;
        padding: 0.6rem 0;
        box-shadow: 0.6rem 0 0 $blue, -0.6rem 0 0 $blue;
        background-color: $blue;
        background-color: $blue;
        line-height: 7.2rem;
      }
    }
    .play-button {
      position: absolute;
      right: 4rem;
      bottom: 4rem;
      font-size: 6rem;
    }
  }
  .box-content {
    background-color: $blue;
    padding: 3rem;
    color: white;
    @media print {
      color: $text-color;
    }
    @media (min-width: $br-medium) {
      width: 50%;
      padding: 6rem;
    }
    h3 {
      margin-bottom: 4rem;
      color: white;
      font-weight: 700;
      @media print {
        color: $text-color;
      }
    }
    h4 {
      font-size: 1.9rem;
      line-height: 2.3rem;
      font-weight: 700;
    }
    p {
      font-size: 1.5rem;
      line-height: 2.3rem;
    }
  }
  .info {
    display: flex;
    align-items: center;
    i {
      font-size: 2.4rem;
      margin-right: 1.2rem;
    }
  }
}

@use "../abstracts" as *;

.live-session-section {
  .container {
    padding-top: 6rem;
    padding-bottom: 6rem;
    max-width: 1332px;
  }
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 2rem;
    button {
      font-size: 2.6rem;
    }
  }
  .heading-2 {
    color: white;
    margin-bottom: 0;
    i {
      margin-right: 2rem;
    }
  }
  .inner {
    position: relative;
    @media (min-width: $br-large) {
      display: flex;
    }
  }
  .video-wrapper {
    position: relative;
    @media (min-width: $br-large) {
      width: 62%;
    }
    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 4rem;
      width: 9rem;
      height: 9rem;
      background-color: white;
      border-radius: 100%;
    }
  }
  .playlist {
    padding-top: 3.2rem;
    background-color: white;
    color: $text-color;
    // flex: 1 1 auto;
    // overflow-y: auto;
    // height: 100%;
    @media (min-width: $br-large) {
      width: 38%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      overflow-y: auto;
    }
    .heading-3 {
      padding: 0 3.8rem;
      font-weight: 700;
      letter-spacing: 0.1em;
    }
  }
  .playlist-item {
    padding: 1.5rem 2rem 1.5rem 3.8rem;
    @media (min-width: $br-large) {
      display: flex;
      align-items: center;
    }
    img {
      width: 9rem;
      margin-right: 3rem;
      flex-shrink: 0;
    }
    &.selected {
      background-color: $blue;
      color: white;
    }
  }
  .playlist-item-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1.6rem;
    font-size: 1.7rem;
    line-height: 2rem;
    font-weight: 700;
    @media (min-width: $br-large) {
      margin-top: 0;
    }
    ul {
      display: flex;
      margin-top: 0.4rem;
      li:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
  .playlist-time {
    color: #5d5e5c;
    font-size: 1.5rem;
    line-height: 1.8rem;
    letter-spacing: 0.1em;
    &.selected {
      color: white;
    }
  }
  .playlist-item-right {
    width: 20%;
    align-self: stretch;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    i {
      font-size: 2.4rem;
    }
    button {
      margin-top: auto;
    }
  }
  .carousel {
    padding-top: 3rem;
  }
  .embla {
    position: relative;
  }
  .embla-viewport {
    overflow: hidden;
  }
  .embla-container {
    display: flex;
  }
  .embla-slide {
    position: relative;
    flex: 0 0 100%;
    margin-right: 3px;
    @media (min-width: $br-large) {
      flex: 0 0 calc((100% / 4) - calc(9px / 4));
    }
  }
  .carousel-item {
    display: flex;
    height: 100%;
    background-color: white;
    padding: 3rem 2rem 2rem 3rem;
    &.selected {
      background-color: $blue;
      p {
        color: white;
      }
    }
  }
  .carousel-item-content {
    width: 100%;
    p {
      color: $text-color;
      font-size: 1.7rem;
      line-height: 2.2rem;
      font-weight: 700;
    }
  }
  .carousel-time {
    margin-bottom: 2rem;
    color: #5d5e5c;
    font-size: 1.4rem;
    line-height: 1.7rem;
    letter-spacing: 0.1em;
    &.selected {
      color: white;
    }
  }
  .carousel-item-right {
    width: 20%;
    align-self: stretch;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    i {
      font-size: 2.4rem;
    }
    button {
      margin-top: auto;
      i {
        font-size: 3.4rem;
      }
    }
  }
  .embla-buttons {
    display: flex;
    justify-content: flex-end;
  }
  .embla-button {
    background-color: $blue;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    font-size: 2.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: $blue-light;
    }
    @media (min-width: $br-xx-large) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .embla-prev {
    margin-right: 1.2rem;
    @media (min-width: $br-xx-large) {
      left: -7rem;
      margin-right: 0;
    }
  }
  .embla-next {
    @media (min-width: $br-xx-large) {
      right: -7rem;
    }
  }
}

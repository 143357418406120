@use "../abstracts" as *;

.podcast-banner-section {
  @media (min-width: $br-large) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left {
    position: relative;
    background-color: $blue;
    color: white;
    @media (min-width: $br-large) {
      display: flex;
      width: calc(50% - 30px);
    }
    @media print {
      color: $text-color;
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5rem 5rem 4rem 2.4rem;
    @media (min-width: $br-large) {
      width: 50%;
    }
    @media print {
      display: inline-block;
    }
    .folge {
      font-size: 1.5rem;
      line-height: 1.8rem;
      font-weight: 700;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    h2 {
      margin-top: 0.8rem;
      margin-bottom: 2rem;
      font-size: 2rem;
      line-height: 3.2rem;
      font-weight: 700;
      letter-spacing: 0.1em;
    }
    .microphone {
      padding-top: 6rem;
      font-size: 12.8rem;
      @media print {
        display: none;
      }
    }
  }
  .image-wrapper {
    @media (min-width: $br-large) {
      width: 50%;
    }
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  .play-button {
    display: none;
    @media (min-width: $br-large) {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 9rem;
    }
  }
  .right {
    margin-top: 3rem;
    @media (min-width: $br-large) {
      margin-top: 0;
    }
    .date {
      font-weight: 700;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 0;
      letter-spacing: 0.1em;
    }
    h3 {
      font-weight: 700;
      letter-spacing: 0.1em;
    }
    @media (min-width: $br-large) {
      width: calc(50% - 30px);
    }
  }
}

@use "../abstracts" as *;

.container {
  max-width: 1236px;
  padding: 0 2.4rem;
  margin: 0 auto;
  @media (min-width: $br-medium) {
    padding: 0 4.8rem;
  }
  @media print {
    width: 1000px;
    i {
      color: $text-color !important;
    }
  }
}

.outer-container {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  &.gold-light {
    background-color: $gold-light;
  }
  &.gold {
    background-color: $gold;
    color: white;
  }
  &.blue {
    background-color: $blue;
    color: white;
    @media print {
      color: $text-color;
    }
  }
  @media print {
    width: 1000px;
    i {
      color: $text-color !important;
    }
  }
}

.placeholder {
  width: 100%;
  height: 200vh;
  @media print {
    height: initial;
  }
}

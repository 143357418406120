@use "../abstracts" as *;

.results-table-section {
  padding: 2.6rem 0 9rem 0;
  .table-heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2rem;
    h2 {
      margin-bottom: 0;
    }
    a {
      flex-shrink: 0;
      margin-top: 1.2rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
    @media (min-width: $br-medium) {
      flex-direction: row;
      align-items: flex-end;
      > a {
        margin-left: 2rem;
        margin-top: 0;
      }
    }
  }
  .table-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    @media print {
      page-break-inside: avoid;
    }
    p {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
    > div:first-child {
      flex-shrink: 0;
    }
    > button,
    a {
      margin-left: 1.2rem;
      flex-shrink: 0;
      font-size: 2.6rem;
      margin-right: 1.2rem;
    }
    .expand {
      font-size: 1.6rem;
      line-height: 1.9rem;
      align-self: center;
    }

    @media (min-width: $br-x-large) {
      flex-wrap: nowrap;
    }
  }
  .table-top-inputs-wrapper {
    > *:not(:first-child) {
      margin-top: 1rem;
    }
    @media (min-width: $br-medium) {
      display: flex;
      align-items: flex-end;
      > *:not(:first-child) {
        margin-left: 1rem;
      }
    }
    @media print {
      display: block;
      > * {
        display: inline-block;
      }
    }
  }
  .button-wrapper {
    width: 100%;
    margin-bottom: 1rem;
    button {
      margin-top: 0.4rem;
      margin-right: 1rem;
    }
    @media (min-width: $br-x-large) {
      margin-left: 3rem;
      margin-bottom: 0;
    }
  }
  table {
    margin-top: 2rem;
    width: 100%;
  }
  th {
    text-align: left;
  }
  td {
    word-break: break-word;
  }
  .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1.6rem 0;
    font-size: 1.5rem;
    line-height: 1.8rem;
    letter-spacing: 0.02em;
    &.white {
      background-color: white;
    }
    .fraction-col {
      width: 100%;
      @media (min-width: $br-large) {
        flex: 1 1 0px;
        &:first-child {
          padding-left: 1rem;
        }
        &.with-padding {
          padding: 0 2rem;
        }
      }
    }
    .expand-col {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      font-size: 2.8rem;
      [aria-expanded="true"] {
        transform: rotate(180deg);
      }
      order: 1;
      @media (min-width: $br-large) {
        width: 5%;
        order: 0;
        margin-top: 0;
      }
    }
    .datum-col {
      width: 50%;
      @media (min-width: $br-large) {
        width: 10%;
      }
    }
    .art-col {
      width: 50%;
      text-align: right;
      @media (min-width: $br-large) {
        text-align: left;
        width: 15%;
      }
    }
    .betreff-col {
      width: 100%;
      margin: 1rem 0;
      a {
        text-decoration: underline;
      }
      @media (min-width: $br-large) {
        width: 35%;
        margin: 0;
        &.short {
          width: 30%;
        }
      }
    }
    .stellungnahmen-col {
      width: 100%;
      @media (min-width: $br-large) {
        width: 7.5%;
      }
    }
    .zustimmungen-col {
      width: 100%;
      @media (min-width: $br-large) {
        width: 7.5%;
      }
    }
    .nummer-col {
      width: calc(100% / 3);
      @media (min-width: $br-large) {
        width: 15%;
        &.short {
          width: 10%;
        }
      }
    }
    .status-col {
      width: calc(100% / 3);
      text-align: center;
      & > span {
        display: inline-block;
        width: 17px;
        height: 17px;
        border-radius: 100%;
        border: 2px solid $blue;
        &:not(:last-child) {
          margin-right: 1rem;
        }
        &.full {
          border: 2px solid $text-color;
          background-color: $blue;
        }
      }
      @media (min-width: $br-large) {
        width: 15%;
        text-align: left;
        &.short {
          width: 10%;
        }
      }
    }
    .buttons-col {
      font-size: 2.2rem;
      width: calc(100% / 3);
      text-align: right;
      @media (min-width: $br-large) {
        width: 5%;
        text-align: left;
      }
    }
    .top-col {
      width: 100%;
      font-weight: 700;
      @media (min-width: $br-large) {
        width: 10%;
      }
    }
    .sitzungsverlauf-col {
      width: 100%;
      @media (min-width: $br-large) {
        width: 65%;
      }
    }
    .files-col {
      width: 100%;
      font-size: 2.4rem;
      @media (min-width: $br-large) {
        width: 10%;
      }
    }
    .zeit-col {
      width: 100%;
      @media (min-width: $br-large) {
        width: 10%;
      }
    }
    .nr-col {
      width: 100%;
      @media (min-width: $br-large) {
        width: 6%;
        text-align: center;
      }
    }
    .rede-beendet-col {
      width: 100%;
      @media (min-width: $br-large) {
        width: 8%;
        text-align: center;
      }
    }
    .rednerinnen-col {
      width: 100%;
      @media (min-width: $br-large) {
        width: 30%;
      }
    }
    .wm-col {
      width: 100%;
      @media (min-width: $br-large) {
        width: 16%;
      }
    }
    .start-col {
      width: 100%;
      @media (min-width: $br-large) {
        width: 6%;
      }
    }
    .dauer-col {
      width: 100%;
      @media (min-width: $br-large) {
        width: 6%;
      }
    }
    .limit-col {
      width: 100%;
      @media (min-width: $br-large) {
        width: 8%;
        text-align: center;
      }
    }
    .zeit-sub-col {
      width: 100%;
      @media (min-width: $br-large) {
        width: 10%;
      }
    }
    .von-col {
      width: 100%;
      a {
        text-decoration: underline;
      }
      @media (min-width: $br-large) {
        width: 50%;
      }
    }
    .content-col {
      order: 1;
      width: 100%;
      padding-top: 1.2rem;
      > a {
        margin-top: 1.2rem;
        flex-shrink: 0;
      }
      @media (min-width: $br-large) {
        display: flex;
        align-items: flex-end;
        padding-top: 2.6rem;
        padding-right: 3rem;
        margin-left: 15%;
        padding-bottom: 2rem;
        > a {
          margin-top: 0;
          margin-left: 1.2rem;
        }
        &.wide {
          margin-left: 5%;
          padding-right: 0;
          > div {
            width: 100%;
          }
          tr {
            flex-wrap: nowrap;
          }
        }
      }
    }
  }
  .heading-row {
    background-color: $blue;
    color: white;
    font-weight: 700;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    i {
      margin-left: 1rem;
    }
    @media (min-width: $br-large) {
      display: flex;
      padding-left: 0;
      padding-right: 0;
    }
    @media print {
      color: $text-color;
    }
  }
  .item-row {
    border-bottom: 1px solid $blue;
    padding: 1.2rem 2.4rem;
    h3 {
      font-size: 1.6rem;
      line-height: 2.3rem;
      font-weight: bold;
    }
    p {
      font-size: 1.5rem;
      line-height: 2.3rem;
    }
    &.expanded {
      background-color: $gold-light;
    }
    @media (min-width: $br-large) {
      padding: 1.6rem 0;
    }
  }
  .bottom {
    > a {
      display: block;
      margin-top: 1.2rem;
    }
    @media (min-width: $br-large) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      > a {
        margin: 0 1rem 0.4rem;
        flex-shrink: 0;
      }
    }
  }
  .attachments {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.6rem;
    > a {
      margin-top: 0.6rem;
      margin-right: 2rem;
      display: flex;
      align-items: center;
      i {
        margin-right: 1rem;
        font-size: 3rem;
      }
    }
  }
  .expand-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.6rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}

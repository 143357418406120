@use "../abstracts" as *;

.cookie-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 1.5rem;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.3);
  @media (min-width: $br-medium) {
    padding: 4rem;
  }
  .modal-inner {
    padding: 2rem;
    background-color: white;
    max-width: 800px;
    margin: 0 auto;
    @media (min-width: $br-medium) {
      padding: 5rem;
    }
  }
  .modal-content {
    margin-top: 2rem;
    > p {
      margin-bottom: 1.4rem;
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
  .button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3rem;
    > button:not(:first-child) {
      margin-top: 1.5rem;
    }
  }
}

.copyright-wrapper {
  position: relative;
  .copyright {
    position: absolute !important;
    bottom: 0.4rem;
    right: 0.4rem;
  }
  .tooltip {
    font-size: 1.2rem !important;
    line-height: 1.4rem !important;
  }
}

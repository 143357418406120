@use "../abstracts" as *;

.filter-section {
  display: flex;
  flex-direction: column;
  .heading-wrapper {
    padding-top: 4rem;
    text-align: center;
  }
  .inner {
    padding: 2rem;
    background-color: $grey;
    input {
      max-width: 100%;
    }
    @media (min-width: $br-medium) {
      padding: 5rem 5rem 3rem 5rem;
      &.small-padding {
        padding: 2rem;
      }
    }
  }
  .filters-wrapper {
    > * {
      width: 100%;
    }
    button {
      max-width: initial;
    }
    > *:not(:first-child) {
      margin-top: 2rem;
    }
    @media (min-width: $br-medium) {
      display: flex;
      align-items: flex-end;
      > *:not(:first-child) {
        margin-top: 0;
        margin-left: 2rem;
      }
      &.large-wrap {
        display: block;
        > *:not(:first-child) {
          margin-top: 2rem;
          margin-left: 0;
          input {
            min-width: 20rem;
          }
        }
      }
    }
    @media (min-width: $br-large) {
      &.large-wrap {
        display: flex;
        > *:not(:first-child) {
          margin-top: 0;
          margin-left: 2rem;
        }
      }
    }
  }
  .buttons-outer-wrapper {
    margin-top: 2.3rem;
    padding-bottom: 1.6rem;
    @media (min-width: $br-medium) {
      display: flex;
      align-items: center;
    }
    p {
      flex-shrink: 0;
      margin-left: auto;
      margin-bottom: 0.8rem;
      padding-left: 1.2rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
      letter-spacing: 0.02em;
    }
  }
  .buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    button {
      margin-bottom: 0.8rem;
    }
    button:not(:last-child) {
      margin-right: 1.2rem;
    }
  }
  .selected-buttons-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 1.2rem;
    border-top: 1px solid $blue;
    button {
      margin-bottom: 0.8rem;
    }
    button:not(:last-child) {
      margin-right: 1rem;
    }
  }
  > button {
    display: flex;
    align-items: center;
    align-self: flex-end;
    text-align: right;
    margin-top: 1rem;
    margin-right: 1.2rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
    i {
      font-size: 3rem;
      margin-left: 1rem;
    }
  }
}

.filter-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  transform: translateX(-100%);
  width: 100%;
  background-color: white;
  z-index: 4;
  overflow-y: auto;
  transition: 0.3s ease-in-out;
  &.hide-visibility {
    transform: translateX(0);
  }
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @media (min-width: $br-medium) {
    width: initial;
    min-width: 500px;
    width: 500px;
    right: -500px;
  }
  .filter-menu-option {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .inner {
    padding: 5rem 4.5rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @media (min-width: $br-large) {
      padding: 5.2rem 4.6rem 6rem;
    }
    > button {
      font-size: 4rem;
    }
  }
  .filter-menu-close-button {
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    align-self: flex-end;
    margin-bottom: 3rem;
    span {
      display: block;
      width: 35px;
      height: 2px;
      background-color: $blue;
      position: relative;
      transform: rotate(45deg);
      &::after {
        content: "";
        width: 35px;
        height: 2px;
        background-color: $blue;
        position: absolute;
        top: 0;
        left: 0;
        transform: rotate(90deg);
        transform-origin: 50%;
      }
    }
    @media (min-width: $br-large) {
      width: 50px;
      height: 50px;
      span {
        width: 50px;
        height: 3px;
        &::after {
          width: 50px;
          height: 3px;
        }
      }
    }
  }
  .filter-menu-back-button {
    position: absolute;
    left: 5rem;
    top: 5rem;
  }
  .filter-menu-close-button-small {
    position: absolute;
    bottom: 2px;
    right: 2px;
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: $text-color !important;
  }
  .accordion-item {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
  .accordion-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.8rem;
    line-height: 2.2rem;
    &[aria-expanded="true"] {
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: 700;
      i {
        transform: rotate(180deg);
      }
    }
  }
  .accordion-content {
    padding-top: 3rem;
    padding-left: 1.8rem;
  }
  .buttons-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 4.2rem 1rem;
    background-color: $gold-light;
    > button:nth-child(2) {
      margin-left: 1.8rem;
    }
  }
}

@use "../abstracts" as *;

.accordion-section {
  padding-top: 40px;
  padding-bottom: 40px;
  & > ul {
    li {
      position: relative;
      border-top: 1px solid $blue;
      > a {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 3.6rem 2rem 3.6rem 7.5rem;
        word-break: break-word;
        &:before {
          position: absolute;
          left: 2rem;
          top: 50%;
          transform: translateY(-50%);
          content: "\f067";
          font-family: "Font Awesome 5 Pro";
          font-size: 3rem;
          font-weight: 400;
        }
        &[aria-expanded="true"] {
          background-color: $gold-light;
          & ~ div {
            background-color: $gold-light;
          }
          &:before {
            content: "\f068";
          }
        }
      }
      > div {
        padding: 0 2rem 2rem;
      }
      &:last-child {
        border-bottom: 1px solid $blue;
      }
      > ul {
        > li {
          border-top: 0px;
          margin-top: -1px;
          > a {
            border-top: 1px solid transparent;
            @media (min-width: $br-medium) {
              padding: 1.8rem 10rem 1.8rem 13.1rem;
            }
            & + .buttons {
              display: none;
              position: absolute;
              top: 1.1rem;
              right: 1rem;
              padding: 0;
              font-size: 2.2rem;
              > button:not(:last-child) {
                margin-right: 2rem;
              }
              @media (min-width: $br-medium) {
                top: 2.1rem;
                right: 2rem;
              }
            }
            & .paragraph-large {
              font-size: 2.5rem;
              line-height: 3rem;
              font-weight: 700;
            }
            &:before {
              @media (min-width: $br-medium) {
                left: 7.7rem;
              }
            }
            &[aria-expanded="true"] {
              border-top: 1px solid $blue;
              & + .buttons {
                display: block;
              }
            }
          }
          > div {
            border-bottom: 1px solid $blue;
            @media (min-width: $br-medium) {
              padding: 0 2rem 2rem 13.1rem;
            }
          }
          &:last-child {
            border-bottom: 0px;
            > div {
              border-bottom: 0px;
            }
          }
        }
      }
    }
  }
  .letter {
    font-size: 4.1rem;
    line-height: 4.9rem;
    font-weight: 700;
    margin-right: 2.5rem;
  }
  .split {
    & > div:nth-child(2) {
      margin-top: 2rem;
    }
    @media (min-width: $br-large) {
      display: flex;
      align-items: center;
      & > div:nth-child(2) {
        flex-shrink: 0;
        width: 35%;
        margin-left: 5rem;
      }
    }
  }
}

@use "../abstracts" as *;

.icon-links-section {
  padding: 4rem 0;
  h2 {
    text-align: center;
  }
  p {
    text-align: center;
    margin-bottom: 4rem;
  }
  .wrapper {
    @media (min-width: $br-medium) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    a {
      flex: 1 1 0px;
      margin: 0 2rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 2rem;
      line-height: 3rem;
      > div:nth-child(2) {
        display: flex;
        align-items: center;
        i {
          margin-left: 0.7rem;
        }
      }
      @media print {
        display: inline-block;
        width: 15%;
      }
    }
  }
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12rem;
    height: 12rem;
    margin-bottom: 2rem;
    background-color: $blue;
    border-radius: 100%;
    &:hover {
      background-color: $blue-light;
    }
    i {
      font-size: 5.5rem;
    }
  }
}

@use "../abstracts" as *;

.form-section {
  padding: 3rem 0;
  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .form-item {
    margin-bottom: 1.4rem;
    width: 100%;
    max-width: 36rem;
    > input,
    > textarea {
      display: block;
      width: 100%;
      font-size: 1.8rem;
      line-height: 2.2rem;
      letter-spacing: 0.02em;
      padding: 0.7rem 1.4rem;
      border: 2px solid $text-color;
      border-radius: 0.2rem;
      &::placeholder {
        color: $text-color;
      }
      &:focus {
        box-shadow: 0 0 0 1px $text-color;
        background-color: $gold-light;
      }
      &:disabled {
        background-color: $grey-light;
        color: $grey-dark;
        border-color: $grey-dark;
        &::placeholder {
          color: $grey-dark;
        }
      }
      &:invalid {
        border-color: #b82820;
      }
      &:valid {
        border-color: #628554;
      }
      &.gold {
        border: 2px solid $gold;
      }
    }
  }
  label,
  p {
    display: block;
    margin-bottom: 0.4rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
  p {
    margin-top: 1.6rem;
  }
  button {
    margin-top: 2.2rem;
  }
}

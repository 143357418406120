@use "../abstracts" as *;

.tour-list-section {
  padding: 0 2.4rem;
  margin: 0 auto;
  @media (min-width: $br-medium) {
    max-width: 1506px;
    padding: 0 4.8rem;
  }
  > ul {
    > li {
      display: flex;
      flex-wrap: wrap;
      @media print {
        page-break-inside: avoid;
      }
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
      h3 {
        font-size: 2.5rem;
        line-height: 3rem;
        font-weight: 700;
        letter-spacing: 0.1em;
        margin-bottom: 1.6rem;
      }
      p {
        font-size: 1.6rem;
        line-height: 2.4rem;
        &.small {
          font-size: 1.3rem;
          line-height: 1.9rem;
          margin-top: 1.3rem;
        }
      }
      i {
        margin-right: 1.3rem;
        font-size: 2.3rem;
        width: 3rem;
      }
      > div:first-child {
        width: 100%;
        img {
          height: 100%;
          object-fit: cover;
        }
        @media (min-width: $br-medium) {
          width: 40%;
        }
        @media (min-width: $br-large) {
          width: 33%;
        }
      }
      > div:nth-child(2) {
        width: 100%;
        padding: 5rem;
        background-color: $blue;
        color: white;
        > div {
          display: flex;
        }
        @media (min-width: $br-medium) {
          width: 60%;
        }
        @media (min-width: $br-large) {
          width: 45%;
        }
        @media print {
          color: $text-color;
        }
      }
      > div:nth-child(3) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 3rem;
        border: 1px solid $blue;
        > ul {
          li {
            display: flex;
            a {
              text-decoration: underline;
            }
          }
          li:not(:last-child) {
            margin-bottom: 1.2rem;
          }
        }
        > a {
          align-self: flex-end;
          margin-top: 2.2rem;
        }
        @media (min-width: $br-large) {
          width: 22%;
          align-items: center;
        }
      }
    }
  }
}

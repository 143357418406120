@use "../abstracts" as *;

.two-column-text-section {
  padding: 4rem 0;
  .column:nth-child(2) {
    margin-top: 1.5rem;
  }
  @media (min-width: $br-medium) {
    .text-wrapper {
      display: flex;
      justify-content: space-between;
    }
    .column {
      width: calc(50% - 15px);
    }
    .column:nth-child(2) {
      margin-top: 0;
    }
  }
}

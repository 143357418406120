@use "../abstracts" as *;

.side-navigation {
  margin-top: 4rem;
  .side-navigation-wrapper {
    display: flex;
    flex-direction: column;
    @media (min-width: $br-large) {
      flex-direction: row;
    }
  }
  .side-navigation-menu {
    width: 100%;
    max-width: 20rem;
    @media (min-width: $br-x-large) {
      max-width: 27rem;
    }
    @media print {
      display: none;
    }
    > ul {
      > li:not(:last-child) {
        margin-bottom: 1rem;
      }
      > li > button {
        width: 100%;
        padding: 2rem;
        border: 1px solid $blue;
        font-size: 1.6rem;
        &:hover {
          background-color: $blue-light;
          border-color: $blue-light;
          color: white;
        }
        &:active {
          background-color: $blue-light;
          border-color: $blue;
          color: white;
        }
        &.selected {
          background-color: $blue;
          color: white;
        }
      }
    }
    .side-navigation-sublist {
      > li > button {
        position: relative;
        width: 100%;
        padding: 2rem;
        border: 1px solid $blue;
        font-size: 1.6rem;
        background-color: $gold-light;
        margin-top: -1px;
        &::after {
          content: "";
          position: absolute;
          bottom: -0.9rem;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
          z-index: 1;
          width: 1.6rem;
          height: 1.6rem;
          background-color: $gold-light;
          border-right: 1px solid $blue;
          border-bottom: 1px solid $blue;
        }
        &.white {
          background-color: white;
          &::after {
            background-color: white;
          }
        }
        &:hover {
          background-color: $blue-light;
          border-color: $blue-light;
          color: white;
          &::after {
            background-color: $blue-light;
          }
        }
        &:active {
          background-color: $blue-light;
          border-color: $blue;
          color: white;
        }
        &.selected {
          background-color: $blue;
          color: white;
          &::after {
            background-color: $blue;
          }
        }
      }
      > li:last-child > button {
        &::after {
          content: none;
        }
      }
    }
  }
  .content {
    width: 100%;
    margin-top: 4rem;
    @media (min-width: $br-large) {
      margin-top: 0;
      margin-left: 6rem;
    }
    > img {
      padding: 4rem 0;
    }
    &.columns {
      @media (min-width: $br-x-large) {
        display: flex;
      }
    }
    .main-column {
      @media (min-width: $br-x-large) {
        width: 65%;
        padding-right: 6rem;
      }
    }
    .side-column {
      @media (min-width: $br-x-large) {
        width: 35%;
      }
    }
  }
}

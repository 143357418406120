@use "../abstracts" as *;

.footer {
  padding-top: 4rem;
  &.no-top-padding {
    padding-top: 0;
  }
  .top-outer {
    background-color: $blue;
  }
  .top {
    padding: 3.6rem 0 6rem;
    color: white;
    @media (min-width: $br-large) {
      display: flex;
      justify-content: space-between;
    }
  }
  .top-left {
    img {
      width: 284px;
      margin-bottom: 1.6rem;
    }
    @media (min-width: $br-large) {
      width: calc(50% - 1.5rem);
    }
  }
  .top-left-content {
    margin-top: 3.2rem;
    > a {
      margin-top: 2.4rem;
    }
    @media (min-width: $br-medium) {
      margin-top: 0rem;
      padding-left: 14.8rem;
    }
  }
  .top-right {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 3rem;
    > ul {
      width: calc(50% - 1.5rem);
      font-size: 2rem;
      line-height: 2.4rem;
      > li:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
    > ul:nth-child(2) {
      padding-left: 2.8rem;
    }
    @media (min-width: $br-large) {
      width: calc(50% - 1.5rem);
      margin-top: 18px;
    }
  }
  .social {
    padding-top: 3rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    font-weight: 700;
    > h3 {
      margin-right: 3rem;
      font-size: 1.7rem;
      line-height: 2.6rem;
      letter-spacing: 0.02em;
      font-weight: 700;
    }
    > ul {
      margin-top: 1.2rem;
      display: flex;
      width: 100%;
      font-size: 3rem;
      > li:not(:first-child) {
        margin-left: 2.4rem;
      }
    }
    @media (min-width: $br-medium) {
      > ul {
        margin-top: 0;
        width: initial;
      }
    }
    @media (min-width: $br-large) {
      margin-top: auto;
    }
  }
  .bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 3.5rem 3.5rem;
    background-color: $grey;
    img {
      margin-left: 1.6rem;
      flex-shrink: 0;
    }
    button {
      font-size: 2.9rem;
      margin-left: 1.2rem;
    }
  }
  .bottom-list {
    display: flex;
    flex-wrap: wrap;
    li:nth-child(1) {
      margin-right: 1.6rem;
    }
    li + li {
      margin-right: 0.6rem;
      &:after {
        content: "|";
        margin-left: 0.6rem;
      }
    }
    li:last-child {
      &:after {
        content: "";
      }
    }
  }
}

@use "../abstracts" as *;

.teaser-carousel-section {
  padding-top: 4rem;
  padding-bottom: 4rem;
  .heading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2rem;
    h2 {
      margin-bottom: 0;
    }
    a {
      flex-shrink: 0;
      margin-top: 1.2rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
    @media (min-width: $br-large) {
      flex-direction: row;
      align-items: flex-end;
      a {
        margin-left: 2rem;
        margin-top: 0;
      }
    }
  }
  .teaser-carousel-inner {
    position: relative;
  }
  .embla {
    position: relative;
  }
  .embla-viewport {
    overflow: hidden;
    padding-bottom: 5rem;
    @media (min-width: $br-xx-large) {
      padding-bottom: 7rem;
    }
    @media print {
      overflow: visible;
    }
  }
  .embla-container {
    display: flex;
    @media print {
      position: relative;
      display: inline-block;
    }
  }
  .embla-slide {
    position: relative;
    flex: 0 0 100%;
    margin-right: 45px;
    @media (min-width: $br-large) {
      flex: 0 0 calc((100% / 3) - 30px);
    }
    @media print {
      display: inline-block;
      float: left;
      width: 400px;
      position: relative;
      margin-bottom: 3rem;
    }
  }
  .embla-buttons {
    display: flex;
    justify-content: flex-end;
    @media print {
      display: none;
    }
  }
  .embla-button {
    background-color: $blue;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    font-size: 2.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: $blue-light;
    }
    @media (min-width: $br-xx-large) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .embla-prev {
    margin-right: 1.2rem;
    @media (min-width: $br-xx-large) {
      left: -9rem;
      margin-right: 0;
    }
  }
  .embla-next {
    @media (min-width: $br-xx-large) {
      right: -9rem;
    }
  }
}

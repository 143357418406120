@use "../abstracts" as *;

.person-banner-section {
  padding-top: 4.8rem;
  .heading {
    font-size: 3.2rem;
    line-height: 3.8rem;
    font-weight: 700;
    margin-bottom: 3.6rem;
    span {
      font-size: 2.2rem;
    }
  }
  .banner {
    position: relative;
    padding: 2rem;
    padding-bottom: 4rem;
    background-color: $blue;
    color: white;
    @media (min-width: $br-medium) {
      padding: 4rem;
    }
    @media (min-width: $br-large) {
      display: flex;
      align-items: center;
    }
    @media print {
      color: $text-color;
    }
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media print {
      display: inline-block;
    }
    img {
      border-radius: 100%;
    }
    .social {
      margin-top: 1.5rem;
      a:not(:last-child) {
        margin-right: 2.4rem;
      }
    }
    margin-bottom: 2rem;
    @media (min-width: $br-large) {
      width: 21%;
      margin-bottom: 0;
    }
  }
  .right {
    @media print {
      display: inline-block;
    }
    .top {
      margin-bottom: 2.4rem;
      p {
        margin-top: 0;
      }
    }
    h3 {
      // margin-bottom: 1.6rem;
      font-size: 2.2rem;
      line-height: 3.1rem;
      font-weight: 700;
      letter-spacing: 0.1em;
    }
    p {
      margin-top: 0.6rem;
    }
    .contact {
      margin-top: 2.4rem;
      li:not(:last-child) {
        margin-bottom: 0.6rem;
      }
      i {
        margin-right: 1.2rem;
      }
    }
    .updated {
      position: absolute;
      bottom: 1.2rem;
      right: 2rem;
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
    @media (min-width: $br-large) {
      width: 79%;
      margin-left: 5rem;
    }
  }
}

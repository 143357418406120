@use "../abstracts" as *;

.text-section {
  margin-bottom: 3.5rem;
  @media print {
    page-break-inside: avoid;
  }
  h3 {
    margin-bottom: 1.8rem;
    font-size: 2.6rem;
    line-height: 3.1rem;
    font-weight: 700;
    color: $blue;
  }
  p {
    font-size: 1.6rem;
    line-height: 2.5rem;
    &.large {
      font-size: 1.9rem;
      line-height: 2.8rem;
    }
  }
  img:not(:last-child) {
    margin-bottom: 2.8rem;
  }
  .expand-button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}

@use "../abstracts" as *;

.radio-input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: 1.8rem;
  > button {
    position: relative;
    width: 100%;
    min-width: 26rem;
    max-width: 26rem;
    padding: 1.1rem 4.5rem 1.1rem 2rem;
    border: 2px solid $blue;
    background-color: white;
    text-align: left;
    &::after {
      content: "\f078";
      position: absolute;
      top: 50%;
      right: 2rem;
      transform: translateY(-50%);
      margin-left: 1.7rem;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      font-size: 1.5rem;
    }
    &.gold {
      border: 2px solid $gold;
      &::after {
        color: $gold;
      }
    }
  }
  .radio-input-list {
    position: absolute;
    z-index: 2;
    left: 0px;
    top: 100%;
    display: flex;
    flex-direction: column;
    margin-top: -0.2rem;
    padding: 2rem;
    border: 2px solid $blue;
    background-color: white;
    &.gold {
      border: 2px solid $gold;
    }
    > div {
      display: flex;
      align-items: center;
      :not(:last-child) {
        margin-bottom: 3rem;
      }
    }
  }
  label {
    position: relative;
    flex-grow: 1;
    margin-left: -1px;
    cursor: pointer;
    padding-right: 4rem;
    &::before {
      content: "";
      position: absolute;
      width: 2rem;
      height: 2rem;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      border: 2px solid $blue;
      border-radius: 100%;
    }
    &::after {
      content: "";
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: 50%;
      right: 0.5rem;
      transform: translateY(-50%);
      border-radius: 100%;
    }
  }
  input {
    opacity: 0;
    width: 1px;
    &:checked + label::after {
      background-color: $blue;
    }
    &:focus {
      + label::before {
        box-shadow: 0 0 0 0.15rem white, 0 0 0 0.3rem $blue;
      }
    }
  }
  a {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
}

.radio-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-top: 1rem;
  .radio-input-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    > div {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $text-color;
      padding: 1.4rem 0;
    }
  }
  label {
    position: relative;
    flex-grow: 1;
    cursor: pointer;
    padding-left: 4rem;
    &::before {
      content: "";
      position: absolute;
      width: 2rem;
      height: 2rem;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      border: 2px solid $blue;
      border-radius: 100%;
    }
    &::after {
      content: "";
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: 50%;
      left: 0.5rem;
      transform: translateY(-50%);
      border-radius: 100%;
    }
  }
  input {
    opacity: 0;
    width: 1px;
    &:checked + label::after {
      background-color: $blue;
    }
    &:focus {
      + label::before {
        box-shadow: 0 0 0 0.15rem white, 0 0 0 0.3rem $blue;
      }
    }
  }
}

.checkbox-wrapper {
  .item {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1.8rem;
    &:not(:last-child) {
      margin-bottom: 3.2rem;
    }
    &.main {
      padding-bottom: 3.2rem;
      border-bottom: 1px solid #dadada;
      label {
        font-weight: 700;
      }
    }
    &.top-border {
      padding-top: 3.2rem;
      border-top: 1px solid #dadada;
    }
  }
  .number {
    position: absolute;
    right: 4rem;
  }
  label {
    width: 100%;
    position: relative;
    flex-grow: 1;
    margin-left: -1px;
    cursor: pointer;
    padding-right: 9rem;
    overflow-wrap: break-word;
    &::before {
      content: "";
      position: absolute;
      width: 2.7rem;
      height: 2.7rem;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      border: 2px solid $blue;
      border-radius: 0.7rem;
    }
    &::after {
      content: "\f00c";
      position: absolute;
      width: 2.7rem;
      height: 2.7rem;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      border-radius: 0.7rem;
      font-family: "Font Awesome 5 Pro";
      color: white;
      font-weight: 700;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.left {
      padding-left: 4rem;
      padding-right: 0;
      &::before {
        right: initial;
        left: 0;
      }
      &::after {
        right: initial;
        left: 0;
      }
    }
  }
  input {
    opacity: 0;
    width: 1px;
    &:checked + label::after {
      background-color: $blue;
    }
    &:focus {
      + label::before {
        box-shadow: 0 0 0 0.15rem white, 0 0 0 0.3rem $blue;
      }
    }
  }
}

.search-input-wrapper {
  &.large {
    width: 100%;
    max-width: 63rem;
  }
  &.flex {
    display: flex;
    > button {
      flex-shrink: 0;
      margin-left: 2rem;
    }
  }
  label {
    display: block;
    margin-bottom: 1.6rem;
    margin-left: 2.2rem;
    font-size: 1.8rem;
    line-height: 2.16rem;
    letter-spacing: 0.02em;
  }
  .input-container {
    width: 100%;
    > button {
      margin-top: 1rem;
    }
    @media (min-width: $br-medium) {
      display: flex;
      align-items: flex-end;
      > button {
        flex-shrink: 0;
        margin-top: 0;
        margin-left: 1.6rem;
      }
    }
  }
  .input-container-left {
    width: 100%;
  }
  .input-wrapper {
    width: 100%;
    position: relative;
    &::after {
      position: absolute;
      right: 2.2rem;
      top: 50%;
      transform: translateY(-50%);
      content: "\f002";
      margin-left: 2rem;
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      pointer-events: none;
      padding-left: 2rem;
    }
    &.gold {
      &::after {
        color: $gold;
      }
    }
  }
  input {
    display: block;
    width: 100%;
    font-size: 1.5rem;
    line-height: 1.7rem;
    letter-spacing: 0.02em;
    padding: 1.1rem 6.2rem 1.1rem 2.2rem;
    border: 2px solid $text-color;
    min-width: 26rem;
    &::placeholder {
      color: $text-color;
    }
    &:focus {
      box-shadow: 0 0 0 1px $text-color;
      background-color: $gold-light;
    }
    &:disabled {
      background-color: $grey-light;
      color: $grey-dark;
      border-color: $grey-dark;
      &::placeholder {
        color: $grey-dark;
      }
    }
    &.gold {
      border: 2px solid $gold;
    }
  }
}

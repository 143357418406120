@use "../abstracts" as *;

.side-column-box {
  border-bottom: 1px solid $blue;
  padding-bottom: 3.6rem;
  margin-bottom: 3.6rem;
  h3 {
    margin-bottom: 0.8rem;
    font-size: 2.2rem;
    line-height: 2.6rem;
    letter-spacing: 0.1em;
    font-weight: 700;
  }
  .attachments {
    > a {
      display: flex;
      align-items: center;
      margin-bottom: 0.6rem;
      font-size: 1.5rem;
      line-height: 1.8rem;
      text-decoration: underline;
      i {
        margin-right: 1rem;
        font-size: 2.1rem;
      }
    }
  }
  img.person {
    max-width: 20rem;
  }
}

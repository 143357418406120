@use "../abstracts" as *;

.hero-section {
  > img {
    min-height: 50rem;
    object-fit: cover;
    @media print {
      width: 100% !important;
    }
  }
  .heading-1 {
    text-align: center;
    padding-top: 12rem;
  }
  .text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10vw;
    margin-right: 10vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .heading-1 {
      margin-bottom: 4rem;
      padding-top: 0;
      color: white;
      text-align: left;
      font-size: 3rem;
      line-height: 4rem;
      margin-left: 0;
      span {
        display: inline;
        padding: 0.6rem 0;
        box-shadow: 0.6rem 0 0 $blue, -0.6rem 0 0 $blue;
        background-color: $blue;
        background-color: $blue;
        line-height: 5.7rem;
      }
      @media (min-width: $br-medium) {
        font-size: 4.5rem;
        line-height: 5.4rem;
        span {
          line-height: 7.2rem;
        }
      }
    }
    li:not(:last-child) {
      margin-bottom: 2rem;
    }
    @media (min-width: $br-medium) {
      top: 8rem;
      transform: translateY(0);
      &.extra-top-margin {
        margin-top: 5rem;
      }
    }
  }
}

@use "../abstracts" as *;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
}

body {
  width: 100%;
  font-family: "Lato", sans-serif;
  font-size: 2.3rem;
  line-height: 3.5rem;
  letter-spacing: 0.02em;
  color: $text-color;
  word-break: break-word;
  @media print {
    position: static !important;
    img {
      width: 40% !important;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 400;
  font-size: inherit;
}

p {
  margin: 0;
  font-size: 1.7rem;
  line-height: 2.6rem;
  letter-spacing: 0.02em;
  & + * {
    margin-top: 2rem;
  }
}

p > a {
  text-decoration: underline;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.7rem;
  line-height: 2.6rem;
  letter-spacing: 0.02em;
}

ol {
  list-style: none;
  padding: 0;
  margin: 0;
  list-style-position: inside;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  letter-spacing: 0.02em;
  // outline: none;
}

img {
  display: block;
  object-fit: contain;
  width: 100%;
  max-width: 100%;
}

figure {
  margin: 0;
}

blockquote {
  margin: 0;
  padding: 0;
}

video {
  vertical-align: middle;
}

input[type="text"],
input[type="email"] {
  appearance: none; /* for iOS */
}

textarea {
  appearance: none; /* for iOS */
}

fieldset {
  border: none;
}

.italic {
  font-style: italic;
}

.ml {
  margin-left: 1rem;
}
.mr {
  margin-right: 1rem;
}
.mb {
  margin-bottom: 1rem;
}

.hide {
  display: none !important;
}

.hide-visibility {
  visibility: hidden;
}

i {
  color: $blue;
  &.gold {
    color: $gold;
  }
  &.white {
    color: white;
  }
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

button:focus-visible,
a:focus-visible,
input:focus-visible {
  box-shadow: 0 0 0 0.2rem white, 0 0 0 0.45rem $blue;
}

button,
a,
input {
  outline: 0.25rem solid transparent;
}

@media print {
  .no-print {
    display: none !important;
  }
}

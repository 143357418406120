@use "../abstracts" as *;

.cta-box-section {
  padding: 4rem 0;
  @media print {
    page-break-inside: avoid;
  }
  .inner {
    padding: 3rem;
    background-color: $grey;
  }
  .column {
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 2rem;
      word-break: break-word;
      > a:first-child {
        margin-bottom: auto;
      }
      a {
        text-decoration: none;
      }
      p {
        margin-top: 1rem;
      }
    }
  }
  @media (min-width: $br-large) {
    .inner {
      padding: 6rem;
      display: flex;
      justify-content: space-between;
    }
    .column {
      &:first-child {
        width: calc(70% - 60px);
      }
      &:nth-child(2) {
        width: calc(30% - 15px);
        margin-top: 0;
      }
    }
  }
}

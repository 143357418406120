@use "../abstracts" as *;

.session-header-section {
  padding-top: 4.8rem;
  .heading {
    font-size: 3.2rem;
    line-height: 3.8rem;
    font-weight: 700;
    margin-bottom: 3.6rem;
    span {
      font-size: 2.2rem;
    }
  }
  .wrapper {
    @media (min-width: $br-medium) {
      display: flex;
    }
  }
  .date {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $blue;
    color: white;
    flex-shrink: 0;
    padding: 2rem 1.6rem;
    @media print {
      color: $text-color;
      display: inline-block;
    }
    .day {
      font-size: 4rem;
      line-height: 4.8rem;
      font-weight: 700;
      margin-bottom: 0.6rem;
    }
    .month {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
    .year {
      font-size: 1.5rem;
      line-height: 1.8rem;
      font-weight: 700;
    }
  }
  .content {
    padding: 3rem 0;
    background-color: $gold-light;
    @media (min-width: $br-medium) {
      display: flex;
      align-items: flex-start;
    }
    @media print {
      display: inline-block;
    }
    ul {
      font-size: 1.5rem;
      line-height: 1.8rem;
      li:not(:last-child) {
        margin-bottom: 1rem;
      }
      a {
        display: flex;
        align-items: center;
      }
      i {
        font-size: 3rem;
        margin-right: 1rem;
      }
    }
    p {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
    p + * {
      margin-top: 0.6rem;
    }
  }
  .col {
    width: 100%;
    padding: 0 3rem;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    @media (min-width: $br-medium) {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
}

.heading-section {
  padding-top: 4rem;
  padding-bottom: 3.2rem;
  .subheading {
    max-width: 870px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .heading-2 {
    text-align: center;
  }
  p {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .buttons-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    > a {
      margin-top: 0.8rem;
      margin-right: 0.8rem;
    }
  }
}

@use "../abstracts" as *;

.secondary-navigation-section {
  position: relative;
  max-width: 100%;
  &::before {
    content: "";
    width: 8rem;
    height: 100%;
    background: linear-gradient(to right, $blue, $blue 50%, rgba($blue, 0));
    position: absolute;
    top: 0px;
    left: 0px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    pointer-events: none;
    z-index: 1;
    @media (min-width: $br-medium) {
      width: 10rem;
    }
  }
  &::after {
    content: "";
    width: 8rem;
    height: 100%;
    background: linear-gradient(to left, $blue, $blue 50%, rgba($blue, 0));
    position: absolute;
    top: 0px;
    right: 0px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    pointer-events: none;
    @media (min-width: $br-medium) {
      width: 10rem;
    }
  }
  &.hide-after {
    &::after {
      display: none;
    }
  }
  &.hide-before {
    &::before {
      display: none;
    }
  }
  .outer-wrapper {
    position: relative;
    margin-bottom: 4rem;
    background-color: $blue;
    overflow-x: auto;
    border-radius: 100px;
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .wrapper {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: white;
    border-radius: 100px;
    a {
      flex-shrink: 0;
      padding: 1.2rem 0.8rem;
      border-radius: 100px;
      border: 2px solid $blue;
      @media (min-width: $br-medium) {
        padding: 1.2rem 1.6rem;
      }
      &.active {
        background-color: white;
        color: $blue;
      }
      &.active.intranet {
        background-color: $gold;
        color: white;
      }
    }
  }
  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    font-size: 2.5rem;
    @media (min-width: $br-medium) {
      font-size: 3rem;
    }
  }
  .button-left {
    left: 0px;
    margin-left: 1rem;
  }
  .button-right {
    right: 0px;
    margin-right: 1rem;
  }
}

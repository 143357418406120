@use "../abstracts" as *;

.teaser-section {
  padding: 4rem 0 7rem;
}
.teaser-inner {
  > div,
  > a {
    margin-bottom: 6rem;
  }
  @media (min-width: $br-large) {
    display: flex;
    > div,
    > a {
      width: calc((100% / 3) - 30px);
      margin-bottom: 0;
      &:not(:last-child) {
        margin-right: 45px;
      }
    }
  }
}

.teaser {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media print {
    page-break-inside: avoid;
    display: inline-block;
    float: left;
    width: 400px;
    position: relative;
    margin-bottom: 3rem;
    margin-right: 3rem;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-top: 2rem;
    padding: 3rem 3rem 4.4rem;
    border: 3px solid $blue;
    flex-grow: 1;
    height: 100%;
    h3 {
      margin-bottom: 1.6rem;
      font-size: 1.8rem;
      line-height: 2.2rem;
      letter-spacing: 0.02em;
      font-weight: 700;
    }
    p {
      font-size: 1.6rem;
      line-height: 1.9rem;
      letter-spacing: 0.02em;
    }
    > a,
    .main-button {
      position: absolute;
      bottom: 0px;
      transform: translateY(50%);
      right: 20px;
    }
    .bottom {
      padding-top: 2rem;
      margin-top: auto;
      align-self: flex-end;
      text-align: right;
      font-size: 1.5rem;
      line-height: 1.8rem;
      i {
        font-size: 2.2rem;
        margin-left: 1.5rem;
      }
    }
  }
}

.teaser-alternate {
  position: relative;
  align-self: flex-start;
  @media print {
    page-break-inside: avoid;
    display: inline-block;
    float: left;
    width: 400px;
    position: relative;
    margin-bottom: 3rem;
    margin-right: 3rem;
  }
  .box {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    right: 2rem;
    padding: 2rem;
    border-left: 7px solid $blue;
    background-color: white;
    @media print {
      position: static;
    }
    h3 {
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #5d5e5c;
      text-transform: uppercase;
      margin-bottom: 0.8rem;
    }
    p {
      font-size: 1.7rem;
      line-height: 2rem;
      font-weight: 700;
      a {
        display: block;
        text-decoration: none;
      }
    }
    button {
      font-size: 2.1rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
}

.pagination-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.8rem;
  font-size: 1.5rem;
  line-height: 1.8rem;
  letter-spacing: 0.02em;
  p {
    margin-bottom: 1.6rem;
    button {
      text-decoration: underline;
    }
  }
}

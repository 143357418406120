@use "../abstracts" as *;

.mediathek-results-list-section {
  padding-top: 6rem;
  padding-bottom: 6rem;
  max-width: 1332px;
  .results-top {
    display: flex;
    flex-wrap: wrap;
    h2 {
      margin-bottom: 0;
    }
    button:not(:first-child) {
      margin-left: 1rem;
      &:nth-child(2) {
        margin-left: 2rem;
      }
      i {
        font-size: 4.6rem;
      }
      &.sign {
        border: 3px solid $blue;
        border-radius: 100%;
        width: 4.6rem;
        height: 4.6rem;
        i {
          font-size: 2.5rem;
        }
      }
    }
    button {
      margin-top: 1rem;
    }
  }
  .inner {
    @media (min-width: $br-large) {
      display: flex;
      justify-content: space-between;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > h2 {
      font-size: 2.5rem;
      line-height: 3rem;
      letter-spacing: 0.1em;
      font-weight: 700;
    }
    @media (min-width: $br-large) {
      width: calc((100% / 3) - 4rem);
      margin-top: 0;
    }
    @media print {
      page-break-inside: avoid;
    }
  }
  .card {
    width: 100%;
    margin-top: 3rem;
  }
  .image-wrapper {
    position: relative;
    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 4rem;
      width: 9rem;
      height: 9rem;
      background-color: white;
      border-radius: 100%;
      @media print {
        display: none;
      }
    }
  }
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 3rem;
    background-color: white;
    color: $text-color;
    border: 3px solid $blue;
    min-height: 217px;
    h3 {
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: 700;
      margin-bottom: 1.2rem;
    }
    p {
      font-size: 1.6rem;
      line-height: 2.5rem;
    }
  }
  .small-card-content {
    padding: 3rem;
    background-color: $blue;
    color: white;
    @media print {
      color: $text-color;
    }
    h3 {
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: 700;
      margin-bottom: 1.2rem;
    }
    p {
      font-size: 1.6rem;
      line-height: 2.5rem;
    }
  }
  .small-card-text {
    display: flex;
    align-items: center;
    i {
      margin-right: 2.4rem;
      font-size: 6rem;
    }
  }
  .card-content-bottom {
    align-self: flex-end;
    margin-top: auto;
    padding-top: 2rem;
    text-align: right;
    font-size: 1.5rem;
    line-height: 1.8rem;
    i {
      margin-left: 1.4rem;
      font-size: 2.2rem;
    }
  }
}

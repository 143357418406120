@use "../abstracts" as *;

.main-button {
  display: inline-block;
  padding: 0.9rem 1.9rem 0.9rem 3.2rem;
  background-color: $blue;
  border: 2px solid $blue;
  border-radius: 10rem;
  font-size: 1.9rem;
  line-height: 2.3rem;
  letter-spacing: 0.1em;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  &.gold {
    background-color: $gold;
    border: 2px solid $gold;
  }
  &.white {
    background-color: white;
    border: 2px solid white;
    color: $gold;
  }
  &::after {
    content: "\f054";
    margin-left: 1.7rem;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    font-size: 1.5rem;
  }

  &.secondary {
    background-color: transparent;
    color: $blue;
  }
  &.inverse {
    padding: 0.9rem 2rem 0.9rem 3.3rem;
    border: 2px solid white;
    font-size: 1.7rem;
    line-height: 2rem;
  }
  &.no-icon {
    padding: 0.9rem 1.9rem;
    &::after {
      content: "";
      margin-left: 0;
    }
  }
  &.down-icon {
    &::after {
      content: "\f078";
    }
  }
  &.small {
    font-size: 1.5rem;
    line-height: 1.8rem;
    padding: 0.5rem 0.7rem 0.5rem 2rem;
    &::after {
      margin-left: 1.4rem;
      font-size: 1.4rem;
    }
    &.no-icon {
      padding: 0.5rem 1.7rem;
      &::after {
        content: "";
        margin-left: 0;
      }
    }
  }
  &.small-font {
    font-size: 1.6rem;
    text-transform: none;
    font-weight: 400;
    letter-spacing: 0.02em;
  }
  &.no-caps {
    text-transform: none;
  }

  &:hover {
    background-color: $blue-light;
    border-color: $blue-light;
    color: white;
  }
  &:active {
    background-color: $blue-light;
    border-color: $blue;
    color: white;
  }
  &:disabled {
    background-color: $grey-light;
    border-color: $grey-light;
    color: $grey-dark;
  }
  @media print {
    color: $text-color !important;
    background-color: white !important;
  }
}

.filter-button {
  display: inline-block;
  padding: 1.2rem 2.3rem;
  border: 2px solid $blue;
  border-radius: 10rem;
  font-size: 1.6rem;
  line-height: 1.9rem;
  letter-spacing: 0.02em;
  &:hover {
    background-color: $blue-light;
    border-color: $blue-light;
    color: white;
  }
  &:disabled {
    background-color: $grey-light;
    border-color: $grey-light;
    color: $grey-dark;
  }
  &.selected {
    background-color: $blue;
    border-color: $blue;
    color: white;
  }
  &.flex {
    display: flex;
    align-items: center;
  }
  &.gold {
    border: 2px solid $gold;
  }
  i {
    font-size: 2.4rem;
    margin-right: 1rem;
  }
  @media print {
    color: $text-color !important;
    background-color: white !important;
  }
}

.selected-button {
  display: inline-block;
  padding: 0.8rem 1.4rem 0.8rem 1.2rem;
  background-color: $blue;
  border-radius: 10rem;
  font-size: 1.5rem;
  line-height: 1.8rem;
  letter-spacing: 0.02em;
  font-weight: 700;
  color: white;
  // @media print {
  //   color: $text-color;
  // }
  &::before {
    content: "\f00d";
    margin-right: 0.9rem;
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
  }
  @media print {
    color: $text-color !important;
    background-color: white !important;
  }
}

.select-button {
  display: inline-block;
  padding: 0.5rem 0.7rem 0.5rem 2rem;
  border: 2px solid $blue;
  border-radius: 10rem;
  font-size: 1.3rem;
  line-height: 1.7rem;
  letter-spacing: 0.1em;
  font-weight: 700;
  color: $blue;
  text-transform: uppercase;
  &::after {
    content: "\f078";
    margin-left: 1.3rem;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    font-size: 1.2rem;
  }
  @media (min-width: $br-large) {
    padding: 0.9rem 1.5rem 0.9rem 2.8rem;
    font-size: 1.7rem;
    line-height: 2rem;
    &::after {
      margin-left: 1.7rem;
      font-size: 1.5rem;
    }
  }
}

.settings-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0.9rem;
  background-color: $blue;
  border-radius: 10rem;
  width: 4.7rem;
  height: 4.7rem;
  &.gold {
    background-color: $gold;
  }
  @media print {
    color: $text-color !important;
    background-color: white !important;
  }
}

@use "../abstracts" as *;

.session-table-section {
  .table-top {
    margin-bottom: 2rem;
  }
  table {
    width: 100%;
    margin-bottom: 3rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
    thead {
      background-color: $blue;
      color: white;
      text-align: left;
      @media print {
        color: $text-color;
      }
      a {
        margin-left: 3.6rem;
        span {
          margin-left: 0.4rem;
        }
      }
    }
    th,
    td {
      padding: 2rem;
    }
    td {
      border-bottom: 1px solid $blue;
      > div:first-child {
        margin-bottom: 1rem;
      }
      @media (min-width: $br-medium) {
        display: flex;
        justify-content: space-between;
        > div:first-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .icons {
    font-size: 2.4rem;
    flex-shrink: 0;
    margin-left: 1rem;
    a:not(:last-child) {
      margin-right: 0.8rem;
    }
  }
}

@use "../abstracts" as *;

.intranet-results-list-section {
  padding: 4rem 0;
  .list > li {
    margin-bottom: 3rem;
  }
  .list-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 1.6rem;
    .left {
      display: flex;
      flex-wrap: wrap;
      > div {
        margin-right: 2rem;
        margin-top: 1rem;
      }
      h2 {
        margin-bottom: 0;
      }
    }
    .expand-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 2rem;
      font-size: 1.5rem;
      line-height: 1.8rem;
      & > button:not(:last-child) {
        margin-right: 3rem;
      }
    }
    @media (min-width: $br-x-large) {
      flex-wrap: nowrap;
    }
  }
  .heading-row {
    display: flex;
    padding: 1rem 0;
    background-color: $blue;
    color: white;
  }
  .expand-button {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &[aria-expanded="true"] {
      transform: rotate(180deg);
    }
    i {
      font-size: 1.8rem;
    }
  }
  .heading {
    display: flex;
    align-items: center;
    padding-left: 2rem;
    font-weight: 700;
    i {
      min-width: 4rem;
      margin-right: 1.4rem;
      font-size: 3rem;
    }
  }
  .sublist > li {
    border-bottom: 1px solid $text-color;
  }
  .subheading-row {
    display: flex;
    padding: 1rem 0;
    @media (min-width: $br-medium) {
      padding: 1rem 0 1rem 5.6rem;
      &.no-padding {
        padding: 1rem 0;
      }
    }
  }
  .subheading {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1.8rem;
    i {
      min-width: 4rem;
      margin-right: 1.4rem;
      font-size: 2.4rem;
    }
  }
  .star-button {
    margin-left: auto;
    padding: 0 2rem;
    font-size: 2.2rem;
  }
  .file-button {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 2.2rem;
    }
  }
}

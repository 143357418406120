@use "../abstracts" as *;

.search-results-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    p {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
    > div:first-child {
      flex-shrink: 0;
    }
    > button,
    a {
      margin-left: 1.2rem;
      flex-shrink: 0;
      font-size: 2.6rem;
      margin-right: 1.2rem;
    }
    .expand {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

    @media (min-width: $br-x-large) {
      flex-wrap: nowrap;
    }
  }
  .top-left {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 1 !important;
    button:not(:first-child) {
      margin-left: 1rem;
      &:nth-child(2) {
        margin-left: 2rem;
      }
      i {
        font-size: 4.6rem;
      }
      &.sign {
        border: 3px solid $blue;
        border-radius: 100%;
        width: 4.6rem;
        height: 4.6rem;
        i {
          font-size: 2.5rem;
        }
      }
    }
    button {
      margin-top: 1rem;
    }
  }
  .results-list {
    margin-top: 2rem;
  }
  .list-item {
    position: relative;
    border: 1px solid $blue;
    padding: 3rem 11% 3rem 3rem;
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
    @media (min-width: $br-large) {
      display: flex;
      justify-content: space-between;
    }
    @media print {
      page-break-inside: avoid;
    }
  }
  .list-item-content {
    @media (min-width: $br-large) {
      display: flex;
    }
    @media print {
      display: inline-block;
      width: 70%;
      > div:last-child {
        display: inline-block;
        width: 70%;
      }
    }
    p {
      font-size: 1.6rem;
      line-height: 2.3rem;
    }
    h3 {
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight: 700;
      margin-bottom: 0.6rem;
    }
  }
  .image-wrapper {
    position: relative;
    width: 100%;
    max-width: 30rem;
    flex-shrink: 0;
    margin-right: 3rem;
    margin-bottom: 2rem;
    align-self: flex-start;
    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 4.4rem;
      @media print {
        display: none;
      }
    }
    @media (min-width: $br-large) {
      width: 14rem;
      margin-bottom: 0;
    }
    @media print {
      display: inline-block;
      width: 20%;
      img {
        width: 100% !important;
      }
    }
  }
  .list-item-top {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: #5d5e5c;
    @media (min-width: $br-large) {
      display: flex;
      align-items: center;
    }
  }
  .list-item-label {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 1rem;
    background-color: $blue;
    color: white;
    text-transform: uppercase;
    padding: 0.6rem;
    font-size: 1.3rem;
    line-height: 1.3rem;
    @media (min-width: $br-large) {
      margin-bottom: 0;
    }
  }
  .social {
    font-size: 2rem;
    a:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
  .circles {
    height: 1.7rem;
    .circle {
      display: inline-block;
      width: 1.7rem;
      height: 1.7rem;
      border-radius: 100%;
      border: 2px solid $blue;
      &.full {
        background-color: $blue;
      }
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
  .right-col {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    flex-shrink: 0;
    margin-top: 3rem;
    padding-left: 3rem;
    @media (min-width: $br-large) {
      margin-top: 0;
    }
    @media print {
      display: inline-block;
    }
  }
  .icons {
    font-size: 2.2rem;
    button:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
  .link-col {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 7.5%;
    @media print {
      display: none;
    }
    a {
      height: 100%;
      width: 100%;
      background-color: $blue;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3.1rem;
    }
  }
}

@use "../abstracts" as *;

.calendar-section {
  padding: 4rem 0;
  .heading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 2rem;
    h2 {
      margin-bottom: 0;
    }
    a {
      flex-shrink: 0;
      margin-top: 1.2rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
    @media (min-width: $br-medium) {
      flex-direction: row;
      a {
        margin-left: 2rem;
        margin-top: 0;
      }
    }
  }
  .button-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3rem;
    button {
      margin-top: 1rem;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
  .calendar-list {
    margin-top: 3rem;
    > li {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      border: 1px solid $blue;
      @media print {
        page-break-inside: avoid;
        > div {
          display: inline-block;
        }
      }
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
      > div {
        flex-shrink: 0;
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
      .date-col {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid $blue;
        padding-right: 7.5%;
        font-size: 1.8rem;
        line-height: 2.2rem;
        text-align: center;
        &.blue {
          background-color: $blue;
          color: white;
          @media print {
            color: $text-color;
          }
        }
        span {
          font-size: 4rem;
          line-height: 4.8rem;
        }
        @media (min-width: $br-large) {
          position: absolute;
          top: 0px;
          left: 0px;
          height: 100%;
          width: 8%;
          border-right: 1px solid $blue;
          border-bottom: 0px;
          padding-right: 0px;
        }
        @media print {
          width: 10rem;
          padding: 0;
        }
      }
      .time-col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 50%;
        padding-left: 4rem;
        font-size: 1.6rem;
        line-height: 1.9rem;
        @media (min-width: $br-large) {
          width: 15%;
          &.has-date {
            margin-left: 8%;
          }
        }
        i {
          margin-top: 1rem;
          font-size: 2.3rem;
        }
      }
      .image-col {
        width: 35%;
        @media (min-width: $br-large) {
          width: 12%;
        }
      }
      .title-col {
        width: 85%;
        align-self: flex-end;
        padding-left: 4rem;
        > p {
          margin-top: 0.6rem;
        }
        @media (min-width: $br-medium) {
          width: 65%;
          &.full-width {
            width: 69.5%;
            margin-left: 8%;
          }
        }
        @media (min-width: $br-large) {
          width: 50.5%;
          padding-left: 3rem;
          &.small {
            width: 42.5%;
          }
          &.large {
            width: 77%;
            padding-left: 0px;
          }
        }
        > div {
          font-size: 1.5rem;
          line-height: 1.8rem;
          letter-spacing: 0.1em;
          color: #5d5e5c;
          text-transform: uppercase;
        }
        h3 {
          margin-top: 0.3rem;
          font-size: 2rem;
          line-height: 2.4rem;
          font-weight: 700;
        }
        ul {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 1rem;
          li > a {
            text-decoration: underline;
            i {
              font-size: 2.6rem;
              margin-right: 0.8rem;
            }
          }
          li > i {
            font-size: 2.6rem;
            margin-right: 0.8rem;
          }
          li:not(:last-child) {
            margin-right: 1.5rem;
          }
        }
      }
      .registration-col {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 85%;
        padding-left: 4rem;
        font-size: 1.6rem;
        line-height: 1.9rem;
        text-align: right;
        @media (min-width: $br-medium) {
          width: 20%;
          flex-direction: column;
        }
        @media (min-width: $br-large) {
          width: 15%;
          padding-right: 2rem;
          padding-left: 0px;
        }
        @media print {
          width: 13rem;
          padding-left: 0;
        }
        i {
          font-size: 2.2rem;
        }
        a > span {
          padding-left: 1rem;
        }
      }
      .link-col {
        padding: 0px;
        a {
          position: absolute;
          top: 0px;
          right: 0px;
          height: 100%;
          width: 7.5%;
          background-color: $blue;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 3.1rem;
        }
        @media print {
          display: none;
        }
      }
    }
  }
}

@use "../abstracts" as *;

.accessibility-bar {
  .accessibility-bar-mobile-button {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45px;
    background-color: $blue;
    color: white;
    border-top: 5px solid white;
    border-right: 5px solid white;
    @media (min-width: $br-medium) {
      display: none;
    }
  }
  .accessibility-bar-list {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 3;
    display: none;
    border-top: 5px solid white;
    border-right: 5px solid white;
    border-bottom: 5px solid white;
    background-color: white;
    &.show {
      display: block;
    }
    @media (min-width: $br-medium) {
      display: block;
    }
  }
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue;
  }
  li + li {
    margin-top: 5px;
  }
  li > button {
    height: 45px;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    background-color: $blue;
    color: white;
  }
  li > div {
    padding: 1.2rem 0;
  }
  li:first-child {
    font-size: 2.2rem;
  }
  li:nth-child(2) {
    font-size: 2.2rem;
  }
  li:nth-child(4) {
    font-size: 2.6rem;
  }
  .small {
    font-size: 1.8rem;
    line-height: 2.1rem;
  }
  .medium {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }
  .large {
    font-size: 2.6rem;
    line-height: 3.1rem;
  }
}

@use "../abstracts" as *;

.overview-section {
  .container {
    padding: 4rem 0;
    max-width: 1332px;
    @media (min-width: $br-large) {
      padding: 8rem 4.8rem;
    }
  }
  .desktop {
    display: none;
    @media (min-width: $br-large) {
      display: block;
      position: relative;
      &:after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: $text-color;
        position: absolute;
        top: calc(50% - 1px);
      }
    }
  }
  .mobile {
    @media (min-width: $br-large) {
      display: none;
    }
  }
  .inner {
    margin-top: 2.6rem;
    align-items: center;
    @media (min-width: $br-large) {
      display: flex;
    }
  }
  .column {
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    align-items: center;
    @media print {
      display: inline-block;
    }
  }
  .item {
    width: 260px;
    &:not(:last-child) {
      margin-bottom: 6.5rem;
    }
    > a {
      display: block;
      max-width: 220px;
      text-align: right;
      margin-left: auto;
      margin-right: 1.5rem;
      margin-top: 1.4rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
  }
  .item-title {
    display: block;
    margin-bottom: 1rem;
    margin-right: 1.5rem;
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 700;
    @media (min-width: $br-large) {
      text-align: right;
    }
  }
  .column:last-child {
    .item {
      > a {
        margin-right: auto;
        margin-left: 1.5rem;
        text-align: left;
      }
      .item-title {
        margin-left: 1.5rem;
        margin-right: 0;
        @media (min-width: $br-large) {
          text-align: left;
        }
      }
    }
  }
  .highlight {
    font-size: 6rem;
    margin-right: 0.6rem;
  }
  .person {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &:not(:first-child) {
      margin-top: 14rem;
    }
    a {
      margin-top: 0.8rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
    img {
      width: 18rem;
      border-radius: 100%;
      margin-top: 1.2rem;
      margin-bottom: 1.2rem;
    }
    @media (min-width: $br-large) {
      &:not(:first-child) {
        margin-top: 0;
      }
    }
    @media print {
      width: 300px;
    }
  }
  .title {
    margin-bottom: 0.4rem;
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 700;
    letter-spacing: 0.1;
  }
  .illustration {
    margin: 3rem 0;
  }
  .grid {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #5d5d5b;
    border-bottom: 1px solid #5d5d5b;
    margin: 3rem 0;
    @media print {
      page-break-inside: avoid;
    }
  }
  .grid-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem;
    &:not(:last-child) {
      border-bottom: 1px solid #5d5d5b;
    }
    @media (min-width: $br-medium) {
      width: 50%;
      &:not(:last-child) {
        border-bottom: 0;
      }
      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: 1px solid #5d5d5b;
      }
      &:nth-child(1),
      &:nth-child(3) {
        border-right: 1px solid #5d5d5b;
      }
    }
    @media print {
      display: inline-block;
      width: 30%;
      page-break-inside: avoid;
    }
  }
}

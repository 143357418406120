@use "../abstracts" as *;

.navigation-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-left: 2.4rem;
  border-bottom: 1px solid #dddddd;
  border-top: 5px solid $blue;
  @media (min-width: $br-large) {
    padding-left: 4.2rem;
    border-top: 7px solid $blue;
  }
  > a {
    display: block;
    width: 160px;
    @media (min-width: $br-large) {
      width: 285px;
    }
  }
  > div {
    display: flex;
    align-items: center;
  }
  .desktop-list {
    display: none;
    @media (min-width: $br-medium) {
      display: flex;
      align-items: center;
      font-size: 3rem;
      @media (min-width: $br-large) {
        font-size: 4rem;
      }
    }
  }
  .mobile-list {
    display: flex;
    align-items: center;
    font-size: 2.5rem;
    width: 100%;
    justify-content: flex-end;
    padding: 1rem 0;
    margin-right: 0.8rem;
    @media (min-width: $br-medium) {
      display: none;
    }
  }
  li + li {
    margin-left: 1.5rem;
    @media (min-width: $br-large) {
      margin-left: 3.5rem;
    }
  }
  .navigation-menu-button {
    background-color: $blue;
    width: 95px;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: $br-medium) {
      margin-left: 3rem;
    }
    @media (min-width: $br-large) {
      width: 144px;
      height: 144px;
      margin-left: 4rem;
    }
    @media (min-width: 2736px) {
      margin-left: 30rem;
    }
    span:first-child {
      display: block;
      width: 35px;
      height: 2px;
      background-color: white;
      position: relative;
      &::after {
        content: "";
        width: 35px;
        height: 2px;
        background-color: white;
        position: absolute;
        top: 11px;
        left: 0px;
      }
      &::before {
        content: "";
        width: 35px;
        height: 2px;
        background-color: white;
        position: absolute;
        top: -11px;
        left: 0px;
      }
      @media (min-width: $br-large) {
        width: 50px;
        height: 3px;
        &::after {
          width: 50px;
          height: 3px;
          top: 14px;
        }
        &::before {
          width: 50px;
          height: 3px;
          top: -14px;
        }
      }
    }
  }
}

.navigation-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  transform: translateX(-100%);
  width: 100%;
  color: white;
  z-index: 4;
  overflow-y: auto;
  transition: 0.3s ease-in-out;
  &.hide-visibility {
    transform: translateX(0);
  }
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @media (min-width: $br-medium) {
    width: initial;
    min-width: 400px;
    width: 400px;
    right: -400px;
  }
  @media (min-width: 2736px) {
    &.hide {
      display: flex !important;
    }
  }
  .inner {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 3.3rem 3rem 4rem;
    background-color: $blue;
    @media (min-width: $br-large) {
      padding: 5.2rem 4.6rem 6rem;
    }
    > button {
      font-size: 4rem;
    }
    > ul {
      margin-top: 6rem;
      font-size: 2.2rem;
      line-height: 2.7rem;
      letter-spacing: 0.1em;
      font-weight: 700;
      max-width: 100%;
      @media (min-width: $br-medium) {
        font-size: 2.6rem;
        line-height: 3.1rem;
      }
      > li {
        text-align: right;
      }
      > li:not(:last-child) {
        margin-bottom: 2rem;
      }
      > li > a {
        margin-right: 3rem;
        display: block;
      }
      > li > a.accordion-item {
        position: relative;
        padding-right: 3rem;
        margin-right: 0;
        &::before {
          position: absolute;
          right: 0rem;
          top: 50%;
          transform: translateY(-50%);
          content: "\f078";
          font-family: "Font Awesome 5 Pro";
          font-size: 2rem;
        }
        &[aria-expanded="true"] {
          &::before {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
      > li > div > ul {
        margin-top: 2.5rem;
        margin-right: 3rem;
        margin-bottom: 2.5rem;
        font-size: 1.9rem;
        line-height: 2.3rem;
        letter-spacing: 0.1em;
        font-weight: 400;
        > li:not(:last-child) {
          margin-bottom: 1rem;
        }
        > li > a {
          margin-right: 3rem;
          display: block;
        }
        > li > a.accordion-item {
          position: relative;
          padding-right: 3rem;
          margin-right: 0;
          &::before {
            position: absolute;
            right: 0rem;
            top: 50%;
            transform: translateY(-50%);
            content: "\f107";
            font-family: "Font Awesome 5 Pro";
          }
          &[aria-expanded="true"] {
            &::before {
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }
        > li > div > ul {
          margin-top: 2rem;
          margin-right: 3rem;
          margin-bottom: 2rem;
          font-size: 1.6rem;
          line-height: 1.9rem;
          letter-spacing: 0.1em;
          > li:not(:last-child) {
            margin-bottom: 0.6rem;
          }
        }
      }
    }
  }

  .navigation-menu-close-button {
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    span {
      display: block;
      width: 35px;
      height: 2px;
      background-color: white;
      position: relative;
      transform: rotate(45deg);
      &::after {
        content: "";
        width: 35px;
        height: 2px;
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        transform: rotate(90deg);
        transform-origin: 50%;
      }
    }
    @media (min-width: $br-large) {
      width: 50px;
      height: 50px;
      span {
        width: 50px;
        height: 3px;
        &::after {
          width: 50px;
          height: 3px;
        }
      }
    }
    @media (min-width: 2736px) {
      visibility: hidden;
    }
  }

  .navigation-menu-close-button-small {
    position: absolute;
    bottom: 2px;
    right: 2px;
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: $text-color !important;
    @media (min-width: 2736px) {
      visibility: hidden;
    }
  }

  .bottom {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: 4rem 4rem;
    background-color: $gold-light;
    font-size: 3rem;
  }
}

@use "../abstracts" as *;

.content-highlight-alternate-section {
  padding-top: 4rem;
  padding-bottom: 4rem;
  .heading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2rem;
    h2 {
      margin-bottom: 0;
    }
    a {
      flex-shrink: 0;
      margin-top: 1.2rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
    @media (min-width: $br-medium) {
      flex-direction: row;
      align-items: flex-end;
      a {
        margin-left: 2rem;
        margin-top: 0;
      }
    }
  }
  .inner {
    padding: 3rem;
    background-color: $blue;
    color: white;
    @media (min-width: $br-medium) {
      display: flex;
      padding: 3rem 5rem 3rem 3rem;
    }
    @media print {
      color: $text-color;
    }
  }
  .date {
    flex-shrink: 0;
    margin-bottom: 2rem;
    margin-right: 6rem;
    font-size: 2.2rem;
    line-height: 2.6rem;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    @media (min-width: $br-medium) {
      margin-bottom: 0;
    }
  }
  .text-wrapper {
    h3 {
      margin-bottom: 0.6rem;
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight: 700;
      color: white;
      @media print {
        color: $text-color;
      }
    }
    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
}

@use "../abstracts" as *;

.topics-section {
  padding: 8rem 2.4rem;
  @media (min-width: $br-medium) {
    padding: 8rem 4.8rem;
  }
  .heading-2 {
    text-align: center;
  }
  .filters-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    > div:not(:last-child) {
      margin-bottom: 1rem;
    }
    @media (min-width: $br-large) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      margin-top: 3rem;
      > div:not(:last-child) {
        margin-right: 3rem;
        margin-bottom: 0;
      }
    }
    @media print {
      display: block;
      > div {
        display: inline-block;
      }
    }
  }
  .results {
    margin-top: 4rem;

    img {
      height: 100%;
      object-fit: cover;
      @media print {
        display: inline-block;
        page-break-inside: avoid;
        width: 20% !important;
      }
    }
    .row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media print {
        display: block;
      }
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
      > div {
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 3rem;
        }
        @media (min-width: $br-xx-large) {
          width: calc(50% - 1.5rem);
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
      .grid {
        display: flex;
        flex-wrap: wrap;
        @media print {
          display: block;
          width: 100% !important;
        }
        > div {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          @media print {
            display: block;
          }
          > div {
            width: 100%;
            &:not(:last-child) {
              margin-bottom: 3rem;
            }
            @media (min-width: $br-medium) {
              width: calc(50% - 1.5rem);
              &:not(:last-child) {
                margin-bottom: 0;
              }
            }
          }
        }
        > div:not(:last-child) {
          margin-bottom: 3rem;
        }
      }
    }
  }
  .item {
    position: relative;
    @media print {
      page-break-inside: avoid;
      width: 100% !important;
    }
  }
  .box {
    position: absolute;
    bottom: 3rem;
    left: 2rem;
    right: 2rem;
    padding: 2rem;
    border-left: 7px solid $blue;
    background-color: white;
    &.no-image {
      position: static;
      height: 100%;
      min-height: 15rem;
    }
    @media (min-width: $br-medium) {
      left: 3rem;
      right: 3rem;
      padding: 3rem;
    }
    @media (min-width: $br-xx-large) {
      right: 16rem;
    }
    @media print {
      position: relative;
      width: 70%;
      display: inline-block;
      page-break-inside: avoid;
      // margin-bottom: 2rem;
    }
    h3 {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #5d5e5c;
      text-transform: uppercase;
      margin-bottom: 0.8rem;
      i {
        margin-right: 0.8rem;
        font-size: 2rem;
      }
      @media (min-width: $br-medium) {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
    p {
      font-size: 1.7rem;
      line-height: 2rem;
      font-weight: 700;
      a {
        display: block;
        text-decoration: none;
      }
      @media (min-width: $br-medium) {
        font-size: 2rem;
        line-height: 2.4rem;
      }
    }
    button {
      font-size: 2.1rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
    &.small {
      left: 2rem;
      right: 2rem;
      padding: 2rem;
      h3 {
        font-size: 1.4rem;
        line-height: 1.7rem;
      }
      p {
        font-size: 1.7rem;
        line-height: 2rem;
      }
    }
  }
}

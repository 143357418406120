@use "../abstracts" as *;

.lightbox-section {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 1.5rem;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.3);
  padding-top: 10rem;
  &.hide {
    display: block !important;
    visibility: hidden;
  }
  @media (min-width: $br-medium) {
    padding: 4rem;
  }
  .lightbox-inner {
    max-width: 1420px;
    margin: 0 auto;
    @media (min-width: $br-medium) {
      padding: 5rem;
    }
  }
  .lightbox-close-button {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 45px;
    height: 45px;
    flex-shrink: 0;
    background-color: white;
    border-radius: 100%;

    span {
      display: block;
      width: 35px;
      height: 2px;
      background-color: $blue;
      position: relative;
      transform: rotate(45deg);
      left: 5px;
      &::after {
        content: "";
        width: 35px;
        height: 2px;
        background-color: $blue;
        position: absolute;
        top: 0;
        left: 0;
        transform: rotate(90deg);
        transform-origin: 50%;
      }
    }
    @media (min-width: $br-large) {
      width: 60px;
      height: 60px;
      span {
        width: 50px;
        height: 3px;
        left: 5px;
        &::after {
          width: 50px;
          height: 3px;
        }
      }
    }
  }
  .lightbox-embla {
    position: relative;
  }
  .lightbox-embla-viewport {
    overflow: hidden;
    padding-bottom: 5rem;
    @media (min-width: $br-xx-large) {
      padding-bottom: 7rem;
    }
  }
  .lightbox-embla-container {
    display: flex;
  }
  .lightbox-embla-slide {
    position: relative;
    flex: 0 0 100%;
    margin-right: 45px;
  }
  .lightbox-embla-buttons {
    display: flex;
    justify-content: flex-end;
  }
  .lightbox-embla-button {
    background-color: $blue;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    font-size: 2.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: $blue-light;
    }
    @media (min-width: $br-xx-large) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .lightbox-embla-prev {
    margin-right: 1.2rem;
    @media (min-width: $br-xx-large) {
      left: -9rem;
      margin-right: 0;
    }
  }
  .lightbox-embla-next {
    @media (min-width: $br-xx-large) {
      right: -9rem;
    }
  }
}

@use "../abstracts" as *;

.timeline {
  display: none;
  position: relative;
  width: calc(100% + 2rem);
  left: -1rem;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  @media (min-width: $br-medium) {
    display: flex;
    justify-content: space-between;
  }
  &::after {
    position: absolute;
    bottom: 0rem;
    left: 1rem;
    content: "";
    width: calc(100% - 2rem);
    height: 2px;
    background-color: $blue;
    z-index: -1;
  }
  button {
    position: relative;
    &::after {
      content: "";
      width: 2rem;
      height: 2rem;
      border: 2px solid $blue;
      position: absolute;
      bottom: -3rem;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 100%;
      background-color: white;
    }
    &.selected {
      &::after {
        background-color: $blue;
      }
      &.large::after {
        width: 3rem;
        height: 3rem;
        bottom: -3.5rem;
      }
    }
  }
}

@use "../abstracts" as *;

.live-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 3rem 2.4rem;
  @media (min-width: $br-medium) {
    padding: 4.8rem;
  }
  @media (min-width: $br-large) {
    padding: 4rem 7rem 4rem 0;
  }
  > div:first-child {
    width: 100%;
    @media (min-width: $br-large) {
      width: calc(100% / 3);
    }
    @media print {
      width: 100%;
      img {
        display: inline-block;
      }
    }
    > div {
      background-color: $blue;
      padding: 3rem;
      color: white;
      display: flex;
      flex-direction: column;
      @media (min-width: $br-large) {
        padding: 7rem 7rem 3rem;
      }
      @media print {
        color: $text-color;
        display: inline-block;
        width: 50%;
      }
      span {
        font-size: 7rem;
      }
      > a {
        align-self: flex-end;
        @media (min-width: $br-large) {
          margin-right: -4rem;
        }
      }
      > h2 {
        margin-bottom: 3rem;
        color: white;
        word-break: break-word;
        @media print {
          color: $text-color;
        }
      }
      > div {
        margin-bottom: 1.2rem;
        display: flex;
        flex-direction: column;
        span {
          font-size: 16rem;
          margin-right: 4rem;
          margin-bottom: 3rem;
          @media print {
            display: none;
          }
        }
        @media (min-width: $br-xx-large) {
          flex-direction: row;
          align-items: center;
          span {
            margin-bottom: 0;
          }
        }
      }
      h3 {
        margin-bottom: 1.2rem;
        font-size: 1.5rem;
        line-height: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;
      }
      p {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }
  > div:nth-child(2) {
    width: 100%;
    margin-top: 3rem;
    @media (min-width: $br-large) {
      width: calc((100% / 3) * 2);
      margin-top: 0;
      padding-left: 4rem;
    }
    .heading-wrapper {
      justify-content: flex-start;
      flex-wrap: wrap;
      h2 {
        margin-right: 3rem;
        flex-shrink: 0;
      }
    }
    .calendar-list {
      > li {
        border-top: 0;
        border-right: 0;
        border-left: 0;
        margin-bottom: 0;
        &:first-child {
          border-top: 1px solid black;
        }
      }
      .time-col {
        width: 85%;
      }
      .title-col {
        width: 85%;
      }
      .registration-col {
        width: 85%;
      }
      @media (min-width: $br-medium) {
        .title-col {
          width: 70%;
        }
        .registration-col {
          width: 20%;
        }
      }
      @media (min-width: $br-large) {
        .time-col {
          width: 15%;
          padding-left: 2rem;
        }
        .title-col {
          width: 62.5%;
        }
        .registration-col {
          width: 22.5%;
        }
      }
    }
  }
}

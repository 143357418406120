@use "../abstracts" as *;

.number-pagination-section {
  display: flex;
  justify-content: flex-end;
  margin-right: 10%;
  margin-top: 1rem;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8rem;
    height: 1.8rem;
    border: 1px solid $blue;
    font-size: 1.3rem;
    line-height: 1.3rem;
    &.selected {
      background-color: $blue;
      color: white;
    }
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

@use "../abstracts" as *;

.tooltip-container {
  position: relative;
  display: inline-block;
  &:hover .tooltip {
    display: block;
  }
  .tooltip {
    white-space: nowrap;
    position: absolute;
    top: -1rem;
    left: 50%;
    transform: translate(-50%, -100%);
    padding: 1.8rem 1.6rem;
    background-color: $blue;
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: white;
    text-align: center;
    display: none;
    &::after {
      content: "";
      width: 2.4rem;
      height: 2.4rem;
      background-color: $blue;
      position: absolute;
      bottom: -1rem;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
    &.white {
      background-color: white;
      color: $blue;
      &::after {
        background-color: white;
      }
    }
  }
}

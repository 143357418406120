@use "../abstracts" as *;

.text-with-table-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 4rem 2.4rem;
  @media (min-width: $br-medium) {
    padding: 4rem 4.8rem;
  }
  @media (min-width: $br-large) {
    padding: 4rem 4.5rem 4rem 0;
  }
  @media print {
    display: block;
  }
  > div:first-child {
    width: 100%;
    @media (min-width: $br-large) {
      width: calc(100% / 3);
    }
    @media print {
      display: inline-block;
    }
    > div {
      background-color: $blue;
      padding: 5rem 7rem 4rem;
      color: white;
      display: flex;
      flex-direction: column;
      @media print {
        color: $text-color;
      }
      > a {
        align-self: flex-end;
      }
      > h2 {
        color: white;
        word-break: break-word;
        @media print {
          color: $text-color;
        }
      }
    }
  }
  > div:nth-child(2) {
    width: 100%;
    margin-top: 3rem;
    @media (min-width: $br-large) {
      width: calc((100% / 3) * 2);
      margin-top: 0;
      padding-left: 4rem;
    }
  }
  table {
    width: 100%;
    margin-bottom: 3rem;
    border-spacing: 0;
    font-size: 1.5rem;
    line-height: 1.8rem;
    thead {
      tr {
        background-color: $blue;
        font-weight: 700;
        color: white;
      }
    }
    th,
    td {
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
      text-align: left;
      border-bottom: 1px solid $blue;
    }
    th:first-child,
    td:first-child {
      width: 20%;
      padding-left: 2rem;
    }
    th:nth-child(2),
    td:nth-child(2) {
      width: 20%;
    }
    th:nth-child(3),
    td:nth-child(3) {
      width: 50%;
    }
    th:nth-child(4),
    td:nth-child(4) {
      width: 10%;
    }
    .link-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 3rem;
      background-color: $blue;
      border-radius: 100%;
      font-size: 1.2rem;
      i {
        padding-top: 0.1rem;
        padding-left: 0.3rem;
      }
    }
  }
}

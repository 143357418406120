@use "../abstracts" as *;

.intranet-icon-links-section {
  padding: 4rem 0;
  h2 {
    text-align: center;
  }
  p {
    text-align: center;
    margin-bottom: 4rem;
  }
  .wrapper {
    color: white;
    @media (min-width: $br-medium) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    @media print {
      color: $text-color;
    }
    a {
      padding: 3.6rem 2rem 2.6rem;
      background-color: $blue;
      flex: 1 1 0px;
      margin: 0 1.5rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @media print {
        display: inline-block;
        width: 15%;
      }
    }
    h3 {
      margin-bottom: 0.8rem;
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight: 700;
    }
    p {
      margin: 0;
      font-size: 1.6rem;
      line-height: 2.3rem;
    }
  }
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    i {
      font-size: 6rem;
    }
  }
}

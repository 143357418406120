@use "../abstracts" as *;

.intranet-support-banner-section {
  margin-top: 4rem;
  @media print {
    color: $text-color;
  }
  .inner {
    padding: 3rem 0;
    @media (min-width: $br-large) {
      padding: 6rem 0;
    }
    h2 {
      color: white;
      display: flex;
      align-items: center;
      i {
        margin-right: 1.6rem;
        font-size: 4.1rem;
      }
      @media print {
        color: $text-color;
      }
    }
    p {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
  }
  .columns-wrapper {
    @media (min-width: $br-large) {
      display: flex;
      justify-content: space-between;
    }
  }
  .column {
    ul {
      li {
        display: flex;
        align-items: flex-start;
        &:not(:last-child) {
          margin-bottom: 2rem;
        }
        i {
          font-size: 2.6rem;
          margin-right: 2rem;
        }
      }
    }
    &:nth-child(2) {
      margin-top: 3rem;
    }
    @media (min-width: $br-large) {
      &:first-child {
        width: calc(70% - 60px);
      }
      &:nth-child(2) {
        width: calc(30% - 15px);
        margin-top: 0;
      }
    }
  }
}

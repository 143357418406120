@use "../abstracts" as *;

.text-with-image-section {
  display: flex;
  flex-direction: column;
  padding: 3.8rem 0;
  @media print {
    display: block;
    page-break-inside: avoid;
  }
  > div {
    margin-bottom: 3rem;
  }
  .primary-button {
    margin-top: 2rem;
  }
  > img {
    order: 1;
  }
  @media (min-width: $br-medium) {
    flex-direction: row;
    justify-content: space-between;
    > div {
      margin-top: 2.4rem;
      margin-bottom: 0;
      width: calc(50% - 15px);
    }
    > img {
      order: initial;
      width: calc(50% - 15px);
    }
  }
}

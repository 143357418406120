@use "../abstracts" as *;

.ausschuss-section {
  margin-bottom: 2.6rem;
  h3 {
    margin-bottom: 1.5rem;
    font-size: 2.2rem;
    line-height: 2.6rem;
    letter-spacing: 0.1em;
    font-weight: 700;
  }
  .ausschuss-list {
    margin-bottom: 6rem;
    li {
      padding: 1.6rem 0;
      @media (min-width: $br-medium) {
        display: flex;
      }
      > div:first-child {
        @media (min-width: $br-medium) {
          width: 25%;
          padding: 0 2rem;
          flex-shrink: 0;
        }
      }
      > div:nth-child(2) {
        @media (min-width: $br-medium) {
          width: 75%;
        }
      }
      border-top: 1px solid $text-color;
      a {
        text-decoration: underline;
      }
      &:last-child {
        border-bottom: 1px solid $text-color;
      }
    }
  }
  .attachments {
    > a {
      display: flex;
      align-items: center;
      margin-bottom: 0.6rem;
      font-size: 1.5rem;
      line-height: 1.8rem;
      text-decoration: underline;
      i {
        margin-right: 1rem;
        font-size: 3rem;
      }
    }
  }
}

@use "../abstracts" as *;

.intranet-content-boxes-section {
  padding: 4rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media print {
    page-break-inside: avoid;
  }
  .box {
    display: flex;
    flex-direction: column;
    width: 100%;
    &.half {
      @media (min-width: $br-large) {
        width: calc(50% - 1.5rem);
      }
    }
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
  .heading {
    padding: 1.6rem 2rem;
    background-color: $blue;
    font-size: 2.2rem;
    line-height: 2.6rem;
    font-weight: 700;
    color: white;
  }
  .box-inner {
    padding: 2.6rem 2rem;
    border: 3px solid $blue;
    height: 100%;
    p {
      font-size: 1.5rem;
      line-height: 2.4rem;
    }
  }
}

@use "../abstracts" as *;

.one-column-text-section {
  padding: 4rem 0;
  @media (min-width: $br-medium) {
    .column {
      width: 75%;
    }
  }
}

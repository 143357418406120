.breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2.2rem;
  ol {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    font-size: 1.6rem;
    line-height: 1.9rem;
    li {
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      margin-right: 0.3rem;
      text-align: right;
    }
    li + li {
      &:before {
        content: ">";
        font-weight: 400;
      }
    }
    li:last-child {
      font-weight: 700;
      margin-right: 0;
    }
  }
  button {
    margin-left: 2rem;
    flex-shrink: 0;
  }
  i {
    font-size: 2.4rem;
  }
}

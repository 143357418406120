@use "../abstracts" as *;

.intranet-special-search {
  @media (min-width: $br-large) {
    display: flex;
  }
  .left {
    @media (min-width: $br-large) {
      width: 50%;
    }
  }
  .right {
    @media (min-width: $br-large) {
      width: 50%;
    }
  }
  .heading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2rem;
    h2 {
      margin-bottom: 0;
    }
    a {
      flex-shrink: 0;
      margin-top: 1.2rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
    @media (min-width: $br-medium) {
      flex-direction: row;
      align-items: flex-end;
      a {
        margin-left: 2rem;
        margin-top: 0;
      }
    }
  }
  .box {
    padding: 3rem 2.4rem;
    @media (min-width: $br-medium) {
      padding: 5rem 4.8rem;
    }
    @media (min-width: $br-large) {
      padding: 5rem 6rem;
    }
    &.blue {
      background-color: $blue;
      color: white;
      .heading-2 {
        color: white;
      }
    }
    &.gold {
      background-color: $gold;
      color: white;
      .heading-2 {
        color: white;
      }
    }
    label {
      margin: 0;
      margin-bottom: 0.8rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
      font-weight: 700;
    }
  }
  .list {
    margin-top: 3rem;
    > li {
      border-top: 1px solid white;
      &:last-child {
        border-bottom: 1px solid white;
      }
    }
  }
  .heading-row {
    display: flex;
    padding: 1rem 0;
  }
  .expand-button {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &[aria-expanded="true"] {
      transform: rotate(180deg);
    }
    i {
      font-size: 1.8rem;
    }
  }
  .heading {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
    i {
      min-width: 4rem;
      margin-right: 1.4rem;
      font-size: 3rem;
    }
  }
  .star-button {
    margin-left: auto;
    padding: 0 1rem;
    font-size: 2.2rem;
  }
  .additional-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2.4rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
  .event-list-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 700;
    text-align: center;
    button {
      font-size: 3.2rem;
    }
  }
  .event-list {
    > li {
      display: flex;
      padding: 1.6rem;
      border-top: 1px solid $text-color;
      &:last-child {
        border-bottom: 1px solid $text-color;
      }
    }
  }
  .time-col {
    width: 25%;
    padding-right: 1rem;
    i {
      margin-top: 0.8rem;
      font-size: 2.2rem;
    }
  }
  .title-col {
    width: 65%;
  }
  .bookmark-col {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 10%;
    padding-left: 1rem;
    font-size: 2.2rem;
  }
}

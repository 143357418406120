@use "../abstracts" as *;

.heading-1 {
  margin-bottom: 1.3rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 4.5rem;
  line-height: 5.4rem;
  letter-spacing: 0.128em;
  font-weight: 700;
  text-align: center;
  color: $blue;
}

.heading-2 {
  margin-bottom: 1.6rem;
  font-size: 3rem;
  line-height: 3.6rem;
  letter-spacing: 0.1em;
  font-weight: 700;
  color: $blue;
}

.heading-3 {
  margin-bottom: 1.6rem;
  font-size: 2.2rem;
  line-height: 3.1rem;
  letter-spacing: 0.02em;
  font-weight: 400;
  color: $blue;
}

.paragraph-large {
  font-size: 2.2rem;
  line-height: 3.1rem;
  letter-spacing: 0.02em;
  font-weight: 400;
}

.unordered-list {
  list-style: disc;
  list-style-position: inside;
  li {
    display: flex;
    &:before {
      content: "";
      position: relative;
      top: 0.7rem;
      width: 1.1rem;
      height: 1.1rem;
      flex-shrink: 0;
      background-color: $blue;
      border-radius: 1.1rem;
      margin-right: 15px;
    }
  }
  li + li {
    margin-top: 1.1rem;
  }
  * + & {
    margin-top: 1.5rem;
  }
}

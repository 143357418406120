@use "../abstracts" as *;

.people-section {
  padding: 4rem 0;
  .list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 1rem;
    & > li {
      display: flex;
      flex-direction: column;
      width: calc(50% - 1rem);
      margin-bottom: 2.4rem;
      &:nth-child(4n + 1),
      &:nth-child(4n + 3) {
        margin-right: 2rem;
      }
      img {
        align-self: center;
        margin-bottom: 1.6rem;
        width: 80%;
        border-radius: 100%;
      }
      @media (min-width: $br-medium) {
        width: calc(25% - 1rem);
        &:nth-child(4n + 1),
        &:nth-child(4n + 2),
        &:nth-child(4n + 3) {
          margin-right: calc(4rem / 3);
        }
      }
      @media print {
        display: inline-block;
        width: 22%;
        margin-right: 2rem;
      }
    }
  }
  .title {
    font-size: 2rem;
    line-height: 2.4rem;
    letter-spacing: 0.05em;
    font-weight: 700;
  }
  .text {
    margin-top: 1.5rem;
    p,
    ul {
      font-size: 1.5rem;
      line-height: 2.3rem;
      letter-spacing: 0.02em;
    }
    h4 {
      margin-top: 1.6rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
      font-weight: 700;
    }
  }
}

@use "../abstracts" as *;

.regierungsvorlage-header-section {
  padding-top: 4.8rem;
  .heading-label {
    margin-bottom: 0.8rem;
    font-size: 1.6rem;
    line-height: 1.9rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #5d5e5c;
  }
  .heading {
    font-size: 3.2rem;
    line-height: 3.8rem;
    letter-spacing: 0.1em;
    font-weight: 700;
    margin-top: 0;
    span {
      font-size: 2.2rem;
    }
  }
  .wrapper {
    margin-top: 3.6rem;
    padding: 3rem 2rem;
    background-color: $blue;
    color: white;
    @media (min-width: $br-medium) {
      display: flex;
    }
    @media print {
      color: $text-color;
    }
  }
  .status {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    padding-right: 2rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 700;
    text-transform: uppercase;
    @media (min-width: $br-medium) {
      width: 10%;
      min-width: 10rem;
    }
    @media print {
      display: inline-block;
    }
    .circles {
      display: flex;
      margin-top: 0.8rem;
      .circle {
        width: 1.7rem;
        height: 1.7rem;
        border-radius: 100%;
        border: 2px solid white;
        @media print {
          border: 2px solid $text-color;
        }
        &.full {
          background-color: white;
          @media print {
            background-color: $text-color;
          }
        }
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }
  .content {
    @media (min-width: $br-large) {
      display: flex;
      align-items: flex-start;
      width: 90%;
    }
    @media print {
      display: inline-block;
    }
  }
  .left-col {
    margin-top: 1.6rem;
    p {
      font-size: 1.6rem;
      line-height: 2.5rem;
      a {
        text-decoration: underline;
      }
      strong {
        font-size: 1.8rem;
        line-height: 2.5rem;
      }
    }
    @media (min-width: $br-large) {
      width: 75%;
      margin-top: 0;
      padding-right: 2rem;
    }
  }
  .right-col {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 1.6rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
    a {
      text-align: right;
      display: block;
      i {
        padding-left: 0.8rem;
      }
      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }
    @media (min-width: $br-large) {
      width: 25%;
      margin-top: 0;
    }
  }
}

@use "../abstracts" as *;

.text-with-gallery-section {
  padding: 4rem 0;
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > a {
      align-self: flex-end;
      margin-top: 2rem;
    }
    table {
      width: 100%;
      font-size: 1.5rem;
      line-height: 1.8rem;
      thead {
        background-color: $blue;
        color: white;
        text-align: left;
        @media print {
          color: $text-color;
        }
      }
      th,
      td {
        padding: 2rem;
      }
      td {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $blue;
      }
    }
    .table-footer {
      padding: 1rem 2rem;
      font-size: 1.3rem;
      line-height: 1.6rem;
    }
    @media print {
      page-break-inside: avoid;
    }
  }
  .gallery {
    margin-top: 3.5rem;
    > h3 {
      margin-top: 6rem;
    }
    @media print {
      page-break-inside: avoid;
    }
  }
  .attachments {
    font-size: 1.5rem;
    line-height: 1.8rem;
    p {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    a {
      display: flex;
      align-items: center;
    }
    i {
      margin-right: 1.2rem;
      font-size: 2.2rem;
    }
  }
  @media (min-width: $br-medium) {
    display: flex;
    justify-content: space-between;
    > div {
      width: calc(50% - 30px);
    }
    .gallery {
      margin-top: 0;
    }
  }
}

.gallery {
  .main-image {
    width: 100%;
  }
  .preview-wrapper {
    display: flex;
    margin-top: 2rem;
  }
  .preview-image {
    width: calc(25% - 15px);
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
